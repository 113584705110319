import React, { useEffect, useState } from 'react';
import axios from 'axios';
// import { Link } from 'react-router-dom';
import './BlogCarousel.css';

const BlogCarousel = () => {
    const [blogs, setBlogs] = useState([]);
    const [currentIndex, setCurrentIndex] = useState(0);
    const [headingVisible, setHeadingVisible] = useState(false);
    const [contentVisible, setContentVisible] = useState(false);

    useEffect(() => {
        axios.get("https://www.veshmn.com/api/blog/")
            .then(response => {
                setBlogs(response.data);
            })
            .catch(error => {
                console.error('Error fetching blogs:', error);
            });
    }, []);

    useEffect(() => {
        const handleScroll = () => {
            const section = document.querySelector('.blog-carousel');
            const sectionPosition = section.getBoundingClientRect().top;
            const screenPosition = window.innerHeight / 1.5;

            if (sectionPosition < screenPosition) {
                setHeadingVisible(true);
                setTimeout(() => {
                    setContentVisible(true);
                }, 0); // Delay to show the content after heading is visible
                window.removeEventListener('scroll', handleScroll);
            }
        };

        window.addEventListener('scroll', handleScroll);
        return () => window.removeEventListener('scroll', handleScroll);
    }, []);

    const nextSlide = () => {
        const slidesToShow = window.innerWidth < 768 ? 1 : 2;
        setCurrentIndex((prevIndex) => (prevIndex + slidesToShow) % blogs.length);
    };

    const prevSlide = () => {
        const slidesToShow = window.innerWidth < 768 ? 1 : 2;
        setCurrentIndex((prevIndex) => (prevIndex - slidesToShow + blogs.length) % blogs.length);
    };

    return (
        <div className="blog-carousel" id="Blog">
            <h2 className={`blog-carousel-heading ${headingVisible ? 'appear' : ''}`}>Latest blogs</h2>
            <div className={`carousel-container ${contentVisible ? 'visible' : ''}`}>
                <button className="carousel-arrow left carousel-button" onClick={prevSlide}>&lt;</button>
                <div className="carousel-slider" style={{ transform: `translateX(-${currentIndex * (100 / (window.innerWidth < 768 ? 1 : 2))}%)` }}>
                    {blogs.map((blog, index) => {
                        const parser = new DOMParser();
                        const doc = parser.parseFromString(blog.content, 'text/html');
                        const heroImage = doc.querySelector('img') ? doc.querySelector('img').src : '';

                        return (
                            <div className="carousel-slide" key={index}>
                                <div className="carousel-card">
                                    <div className="carousel-image-wrapper">
                                        <img src={heroImage} alt={blog.title} className="carousel-image" />
                                    </div>
                                    <div className="carousel-content">
                                        <h3>
                                            <a href={`/${blog.title}/${blog.id}`} target="_blank" rel="noopener noreferrer">{blog.title}</a>
                                        </h3>
                                    </div>
                                </div>
                            </div>
                        );
                    })}
                </div>
                <button className="carousel-arrow right carousel-button" onClick={nextSlide}>&gt;</button>
            </div>
        </div>
    );
};

export default BlogCarousel;
