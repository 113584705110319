import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './Render.css';

const RenderVideo = () => {
  const [headingVisible, setHeadingVisible] = useState(false);
  const [contentVisible, setContentVisible] = useState(false);
  const [videoUrl, setVideoUrl] = useState('');

  useEffect(() => {
    // Fetch the video URL from the backend
    axios.get('https://www.veshmn.com/api/herovideo/')
      .then(response => {
        const videoData = response.data;
        if (videoData.length > 0) {
          setVideoUrl(videoData[0].hero_video);
        }
      })
      .catch(error => {
        console.error('Error fetching the video URL:', error);
      });
    console.log(videoUrl);
    const handleScroll = () => {
      const section = document.querySelector('.render-video-wrapper');
      const sectionPosition = section.getBoundingClientRect().top;
      const screenPosition = window.innerHeight / 1.5;

      if (sectionPosition < screenPosition) {
        setHeadingVisible(true);
        setTimeout(() => {
          setContentVisible(true);
        }, 500); // Delay to show the content after heading is visible
        window.removeEventListener('scroll', handleScroll);
      }
    };
    
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, [videoUrl]);

  return (
    <div className="render-video-wrapper">
      <h2 className={`render-heading ${headingVisible ? 'appear' : ''}`}>Experience it, before it's built</h2>
      <p className={`render-kit-description ${contentVisible ? 'visible' : ''}`}>
        Experience the 3D walkthrough of your home before finalizing the design. Explore every corner and get a detailed view of each room, ensuring everything is perfect before construction begins. Take a virtual tour and see your vision come to life, down to the smallest detail.
      </p>
      <div className={`render-video-container ${contentVisible ? 'visible' : ''}`}>
        {videoUrl && (
          <video autoPlay loop muted playsInline className="render-background-video">
            <source src={videoUrl} type="video/mp4" />
            Your browser does not support the video tag.
          </video>
        )}
      </div>
    </div>
  );
};

export default RenderVideo;