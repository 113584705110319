import React, { useEffect, useRef, useState } from 'react';
import './body.css';
import KitchenImage_F from './Image/KitchenImage_F.webp';
import LivingRoomImage_F from './Image/LivingRoomImage_F.webp';
import BedroomImage_F from './Image/BedroomImage_F.webp';
import TimelessInterior from './Image/TimelessInterior.webp';

function BodyComponent() {
  const sectionRefs = useRef([]);
  const [visibleSections, setVisibleSections] = useState([]);

  useEffect(() => {
    const handleScroll = () => {
      const windowHeight = window.innerHeight;

      sectionRefs.current.forEach((section, index) => {
        if (section) {
          const sectionTop = section.getBoundingClientRect().top;
          if (sectionTop < windowHeight && !visibleSections.includes(index)) {
            setVisibleSections((prev) => [...prev, index]);
          }
        }
      });
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, [visibleSections]);

  return (
    <section>
      <div className="container-fluid">
        {[
          {
            heading: "Timeless interior",
            text: "Home interiors are designed keeping in mind the timeless principals which means every time you experience it, you experience it as good as new.",
            image: TimelessInterior,
          },
          {
            heading: "Joyful gatherings",
            text: "A living room designed to enhance family time and happiness, where everyone loves to sit and talk. It's a space that brings joy and togetherness.",
            image: LivingRoomImage_F,
          },
          {
            heading: "Heart of happiness",
            text: "A Vastu-compliant, spacious kitchen with ample storage. It’s a place that keeps you in a good mood, inspiring loved and tasty food.",
            image: KitchenImage_F,
          },
          {
            heading: "Calm corner",
            text: "A calm, relaxing bedroom that offers a private sanctuary. A space to unwind, reflect, and overcome the day's stress while maintaining your privacy.",
            image: BedroomImage_F,
          }
        ].map((section, index) => (
          <div
            key={index}
            className={`py-5 card-box ${visibleSections.includes(index) ? 'appear' : ''}`}
            ref={(el) => sectionRefs.current[index] = el}
          >
            <h2 className="display-4 mb-5 body-heading">{section.heading}</h2>
            <img src={section.image} alt="img" className="img-fluid" />
            <p className="body">{section.text}</p>
          </div>
        ))}
      </div>
    </section>
  );
}

export default BodyComponent;
