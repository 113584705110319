import React, { useState, useEffect } from 'react';
import './Gallery.css';
import axios from 'axios';


const GallerySection = () => {
    const [isZoomedOut, setIsZoomedOut] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [currentImageIndex, setCurrentImageIndex] = useState(0);
    const [headingVisible, setHeadingVisible] = useState(false);
    const [contentVisible, setContentVisible] = useState(false);
    const [images, setImages] = useState([]);

    useEffect(() => {
        
        axios.get('https://www.veshmn.com/api/interiorimagegallary/')
            .then(response => {
                const imageData = response.data;
                const imageUrls = imageData.map(item => item.ImageGallery);
                setImages(imageUrls);
            })
            .catch(error => {
                console.error('Error fetching images:', error);
            });
        

        const handleScroll = () => {
            const section = document.querySelector('.gallery-section');
            const sectionPosition = section.getBoundingClientRect().top;
            const screenPosition = window.innerHeight / 1.5;

            if (sectionPosition < screenPosition) {
                setHeadingVisible(true);
                setTimeout(() => {
                    setContentVisible(true);
                    setIsZoomedOut(true);
                }, 500); // Delay to show the content after heading is visible
                window.removeEventListener('scroll', handleScroll);
            }
        };

        window.addEventListener('scroll', handleScroll);
        return () => window.removeEventListener('scroll', handleScroll);
    }, []);

    const openModal = (index) => {
        setCurrentImageIndex(index);
        setIsModalOpen(true);
    };

    const closeModal = () => {
        setIsModalOpen(false);
    };

    const nextImage = (e) => {
        e.stopPropagation(); // Prevent modal from closing
        setCurrentImageIndex((prevIndex) => (prevIndex + 1) % images.length);
    };

    const prevImage = (e) => {
        e.stopPropagation(); // Prevent modal from closing
        setCurrentImageIndex((prevIndex) => (prevIndex - 1 + images.length) % images.length);
    };

    return (
        <div className="gallery-section">
            <h2 className={`gallery-heading ${headingVisible ? 'appear' : ''}`}>Veshmn designs and renders</h2>
            <div className={`collage-container ${contentVisible ? 'visible' : ''} ${isZoomedOut ? 'zoom-out' : ''}`}>
                {images.map((image, index) => (
                    <div className="collage-item" key={index} onClick={() => openModal(index)}>
                        <img src={image} alt={`Design ${index + 1}`} />
                    </div>
                ))}
            </div>

            {isModalOpen && (
                <div className="gallery-modal" onClick={closeModal}>
                    <span className="close" onClick={closeModal}>&times;</span>
                    <span className="prev" onClick={prevImage}>&#10094;</span>
                    <img src={images[currentImageIndex]} alt={`Design ${currentImageIndex + 1}`} onClick={(e) => e.stopPropagation()} />
                    <span className="next" onClick={nextImage}>&#10095;</span>
                    <div className="counter">{`${currentImageIndex + 1} / ${images.length}`}</div>
                </div>
            )}
        </div>
    );
};

export default GallerySection;
