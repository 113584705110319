import React, { useEffect, useState, useRef } from "react";
import axios from "axios";
import './Story.css';
import { useParams } from 'react-router-dom';

function StoryOfCustomer() {
    const [storyData, setStoryData] = useState({});
    const [isVisible, setIsVisible] = useState(false);
    const { planId } = useParams();
    const sectionRef = useRef(null);

    useEffect(() => {
        // Make a GET request to fetch the story data
        axios.get(`https://www.veshmn.com/api/story-data/${planId}/`)
            .then(response => {
                setStoryData(response.data);
            })
            .catch(error => {
                console.error('Error fetching story data:', error);
            });
    }, [planId]);  // Empty dependency array means this effect runs once when the component mounts

    useEffect(() => {
        const handleScroll = () => {
            if (sectionRef.current) {
                const section = sectionRef.current;
                const sectionPosition = section.getBoundingClientRect().top;
                const screenPosition = window.innerHeight / 1.3;

                if (sectionPosition < screenPosition) {
                    setIsVisible(true);
                    window.removeEventListener('scroll', handleScroll);
                }
            }
        };

        window.addEventListener('scroll', handleScroll);
        return () => window.removeEventListener('scroll', handleScroll);
    }, []);

    return (
        <section className={`py-5 story ${isVisible ? 'appear' : ''}`} ref={sectionRef}>
            <div className="container-fluid">
                <div className="row text-center">
                    <h2 className="display-5 ">{storyData.title}</h2>
                    <p className="mb-4">{storyData.content}</p>
                </div>
            </div>
        </section>
    );
}

export default StoryOfCustomer;
