import React from 'react';
import undefined_image from './Image/undefined_image.png';
import './Thankyou.css'

const Thankyou = () => {

  return (
    <div className="container-thankyou">
      <div className="image-container-thankyou">
        <img src={undefined_image} className="img-undefined" alt="img-thankyou" />
      </div>
      <div className="text-container-thankyou">
        <h2 style={{fontFamily:'font-family: SF Pro Display, SF Pro Icons, Helvetica Neue, Helvetica, Arial, sans-seri'}} >
            <span style={{fontWeight: 'bold'}}> Thank  you! Your call has been scheduled. </span>
            <br></br>
            We will reach out to you in the next 24 hours.
            <br></br>
            In the mean time check out our <a href="https://www.instagram.com/veshmn.official/" target="_blank" rel="noopener noreferrer">Instagram</a>.</h2>
            </div>
    </div>
  );
}

export default Thankyou;
