import React, { useEffect, useRef, useState, useCallback } from 'react';
import { Canvas, useThree, extend } from '@react-three/fiber';
import { TextureLoader, LinearFilter, NearestMipMapLinearFilter } from 'three';
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls';
import * as THREE from 'three';
// import icon from './Image/right.png';
// import button from './Image/circle.png';
import Interior from './Image/Interior.jpg'
import { useParams } from 'react-router-dom';
import loaderImage from './Image/logo2.png';

extend({ OrbitControls });

const CameraControls = () => {
  const { camera, gl } = useThree();
  const controlsRef = useRef();
  const maxZoom = 10;  // Increased max zoom level
  const minZoom = 1;   // Decreased min zoom level
  const zoomStep = 0.1;

  useEffect(() => {
    const controls = new OrbitControls(camera, gl.domElement);
    controls.enableDamping = true;
    controls.dampingFactor = 0.25;
    controls.enablePan = false;
    controls.minDistance = 300 / maxZoom;
    controls.maxDistance = 300 / minZoom;
    controlsRef.current = controls;

    const handleWheel = (event) => {
      event.preventDefault();
      const delta = Math.sign(event.deltaY);
      if (delta > 0 && camera.zoom > minZoom) {
        camera.zoom = Math.max(minZoom, camera.zoom - zoomStep);
      } else if (delta < 0 && camera.zoom < maxZoom) {
        camera.zoom = Math.min(maxZoom, camera.zoom + zoomStep);
      }
      camera.updateProjectionMatrix();
    };

    const handleTouch = (event) => {
      if (event.touches.length === 2) {
        const touch1 = event.touches[0];
        const touch2 = event.touches[1];
        const distance = Math.sqrt(
          Math.pow(touch2.clientX - touch1.clientX, 2) + Math.pow(touch2.clientY - touch1.clientY, 2)
        );
        if (!controlsRef.current.initialDistance) {
          controlsRef.current.initialDistance = distance;
        } else {
          const deltaDistance = distance - controlsRef.current.initialDistance;
          if (deltaDistance > 0 && camera.zoom < maxZoom) {
            camera.zoom = Math.min(maxZoom, camera.zoom + zoomStep);
          } else if (deltaDistance < 0 && camera.zoom > minZoom) {
            camera.zoom = Math.max(minZoom, camera.zoom - zoomStep);
          }
          camera.updateProjectionMatrix();
          controlsRef.current.initialDistance = distance;
        }
      }
    };

    gl.domElement.addEventListener('wheel', handleWheel);
    gl.domElement.addEventListener('touchmove', handleTouch);

    return () => {
      controls.dispose();
      gl.domElement.removeEventListener('wheel', handleWheel);
      gl.domElement.removeEventListener('touchmove', handleTouch);
    };
  }, [camera, gl]);

  return null;
};

const useTexture = (url) => {
  const [texture, setTexture] = useState(null);
  const [loading, setLoading] = useState(true); // Manage loading state

  useEffect(() => {
    const loader = new TextureLoader();
    loader.load(
      url,
      (loadedTexture) => {
        loadedTexture.minFilter = NearestMipMapLinearFilter; // Use high-quality mipmap filter
        loadedTexture.magFilter = LinearFilter; // Use linear filtering for magnification
        loadedTexture.colorSpace = THREE.LinearSRGBColorSpace;  // Ensure correct color space
        loadedTexture.needsUpdate = true;

        // Correct the flipping of the texture
        loadedTexture.wrapS = THREE.RepeatWrapping;
        loadedTexture.repeat.x = 1;

        setTexture(loadedTexture);
        setLoading(false); // Set loading to false when texture is loaded
      },
      undefined,
      (error) => {
        console.error('Error loading texture:', error);
        setLoading(false); // Set loading to false on error
      }
    );
  }, [url]);

  return { texture, loading };
};

const PanoramaMesh = ({ texture, isVisible }) => {
  return texture ? (
    <mesh scale={[1, 1, 1]} className={`panorama-mesh ${isVisible ? 'visible' : ''}`}>
      <sphereGeometry args={[500, 100, 50]} />  {/* Increased segment count for higher detail */}
      <meshStandardMaterial map={texture} side={THREE.BackSide} roughness={0.5} metalness={0.1} />
    </mesh>
  ) : null;
};

const ImageThumbnails = ({ images, currentIndex, onClick }) => (
  <div style={{
    position: 'absolute', bottom: '5%', left: '50%', transform: 'translateX(-50%)', display: 'flex', flexWrap: 'wrap', gap: '10px', justifyContent: 'center'
  }}>
    {images.map((image, index) => (
      <div key={index} onClick={() => onClick(index)} style={{ cursor: 'pointer', textAlign: 'center' }}>
        <div style={{ color: 'white', fontSize: '12px', marginBottom: '5px' }}>{image.name}</div>
        <img src={image.src} alt={`Thumbnail ${index}`} style={{
          width: '50px', height: '50px', borderRadius: '50%', border: currentIndex === index ? '2px solid white' : '2px solid transparent', boxShadow: currentIndex === index ? '0 0 10px rgba(255, 255, 255, 0.5)' : 'none', objectFit: 'cover'
        }} />
      </div>
    ))}
  </div>
);

const Temp = () => {
  const { planId } = useParams();
  const [images, setImages] = useState([]);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [isTransitioning, setIsTransitioning] = useState(false);
  const [loading, setLoading] = useState(true); // Manage loading state for fetching images

  useEffect(() => {
    // Fetch data from the API
    setLoading(true);
    fetch(`https://www.veshmn.com/api/new-threed-model/${planId}/`)
      .then(response => response.json())
      .then(data => {
        const fetchedImages = [
          { src: data.image1, name: data.name1 },
          { src: data.image2, name: data.name2 },
          { src: data.image3, name: data.name3 },
          { src: data.image4, name: data.name4 },
          { src: data.image5, name: data.name5 },
          { src: Interior, name: "Interior" },
        ];
        setImages(fetchedImages);
        setLoading(false); // Set loading to false after images are fetched
      })
      .catch(error => {
        console.error('Error fetching images:', error);
        setLoading(false); // Set loading to false on error
      });
  }, [planId]);

  const { texture: currentTexture, loading: textureLoading } = useTexture(images[currentIndex]?.src);

 

  const handleThumbnailClick = useCallback((index) => {
    setIsTransitioning(true);
    setTimeout(() => {
      setCurrentIndex(index);
      setIsTransitioning(false);
    }, 500);
  }, []);

  return (
    <div style={{ position: 'relative', height: '100vh', width: '100%' }}>
      <Canvas style={{ width: '100%', height: '100%', borderRadius: '20px', border: '2px solid', borderColor: '#0096d8' }} camera={{ fov: 75, aspect: window.innerWidth / window.innerHeight, near: 0.1, far: 1000 }}
        gl={{ antialias: true }}
        onCreated={({ gl }) => {
          gl.toneMapping = THREE.ACESFilmicToneMapping;
          gl.outputColorSpace = THREE.LinearSRGBColorSpace;  // Set correct output color space
        }}
      >
        <ambientLight intensity={2} />  {/* Adjusted ambient light intensity for better lighting */}
        <directionalLight
          position={[5, 10, 7.5]}
          intensity={1}
          castShadow
          shadow-mapSize-width={4096}
          shadow-mapSize-height={4096}
          shadow-camera-near={0.1}
          shadow-camera-far={50}
          shadow-camera-left={-20}
          shadow-camera-right={20}
          shadow-camera-top={20}
          shadow-camera-bottom={-20}
        />
        <PanoramaMesh texture={currentTexture} isVisible={!isTransitioning} />
        <CameraControls />
      </Canvas>
      {(loading || textureLoading) && (
        <div className="loader-3d" style={{ position: 'absolute', top: '35%', left: '50%', transform: 'translate(-50%, -50%)', width: '100px', height: '100px' }}>
          <img src={loaderImage} alt="Loading..." style={{ width: '100%', height: '100%', animation: 'spin 1s linear infinite' }} />
        </div>
      )}
      <ImageThumbnails images={images} currentIndex={currentIndex} onClick={handleThumbnailClick} />
    </div>
  );
};

export default Temp;