import React, { useState, useRef, useEffect } from 'react';
import './WhoeverYouAre.css';
import son from './Images/son.png';
import father from './Images/father.png';
import mother from './Images/mother.png';
import daughter from './Images/daughter.png';

const data = [
    {
        title: "A Father",
        text: "For a father, building a home is about providing for his family and creating a lasting legacy. Whether it's for a child's wedding, starting a new family, or fulfilling a partner's dream, it represents closure, social status. Veshmn understands a father's dedication and is ready to help turn this dream into reality.",
        image: father
    },
    {
        title: "A Daughter",
        text: "For a daughter, building a home is a heartfelt way to say thank you to her parents. It’s about creating a space that honors their sacrifices and love, providing them with comfort and joy. Veshmn appreciates the sentiment and is committed to helping you create this special place.",
        image: daughter
    },
    {
        title: "A Son",
        text: "For a son, building a home is a way to thank his parents for their lifelong support. It’s a gesture of gratitude, offering security and comfort in their old age. It symbolizes accomplishment and a desire to give back. Veshmn knows the importance of this gesture and is here to help you make it happen.",
        image: son
    },
    {
        title: "A Mother",
        text: "For a mother, building a home is about providing a safe and healthy environment for her children. It’s a reflection of her protective nature and her desire to nurture and care for her family. Veshmn understands a mother's love and is dedicated to helping you build this nurturing space.",
        image: mother
    },
];

const WhoeverYouAre = () => {
    const [headingVisible, setHeadingVisible] = useState(false);
    const [contentVisible, setContentVisible] = useState(false);
    const [currentIndex, setCurrentIndex] = useState(0);
    const sliderRef = useRef();
    const touchStartX = useRef(0);
    const isDragging = useRef(false);

    useEffect(() => {
        const handleScroll = () => {
            const section = document.querySelector('.whoever-you-are');
            const sectionPosition = section.getBoundingClientRect().top;
            const screenPosition = window.innerHeight / 1.5;

            if (sectionPosition < screenPosition) {
                setHeadingVisible(true);
                setTimeout(() => {
                    setContentVisible(true);
                }, 500); // Delay to show the content after heading is visible
                window.removeEventListener('scroll', handleScroll);
            }
        };

        window.addEventListener('scroll', handleScroll);
        return () => window.removeEventListener('scroll', handleScroll);
    }, []);

    const nextSlide = () => {
        setCurrentIndex((prevIndex) => (prevIndex + 1) % data.length);
    };

    const prevSlide = () => {
        setCurrentIndex((prevIndex) => (prevIndex - 1 + data.length) % data.length);
    };

    // Handle touch start event
    const handleTouchStart = (e) => {
        touchStartX.current = e.touches[0].clientX;
        isDragging.current = true;
    };

    // Handle touch move event
    const handleTouchMove = (e) => {
        if (!isDragging.current) return;
        const touchMoveX = e.touches[0].clientX;
        const diffX = touchMoveX - touchStartX.current;

        // Prevent fast skipping of slides
        if (Math.abs(diffX) > 50) {
            if (diffX > 0) {
                prevSlide();
            } else {
                nextSlide();
            }
            isDragging.current = false; // Stop further slide change until new touch event
        }
    };

    // Handle touch end event
    const handleTouchEnd = () => {
        isDragging.current = false;
    };

    return (
        <div className="whoever-you-are">
            <h2 className={`whoever-heading ${headingVisible ? 'appear' : ''}`}>Veshmn for all of you</h2>
            <div className={`slider-container ${contentVisible ? 'visible' : ''}`}
                onTouchStart={handleTouchStart}
                onTouchMove={handleTouchMove}
                onTouchEnd={handleTouchEnd}
                ref={sliderRef}
            >
                <div className="slider" style={{ transform: `translateX(-${currentIndex * 85}%)` }}>
                    {data.map((item, index) => (
                        <div
                            className={`slide ${index === currentIndex ? 'active' : ''}`}
                            key={index}
                        >
                            <img src={item.image} alt={item.title} className="slide-image" />
                            <h3>{item.title}</h3>
                            <p>{item.text}</p>
                        </div>
                    ))}
                </div>
                <div className="arrow-container">
                    <button className="slider-arrow left" onClick={prevSlide}>&lt;</button>
                    <button className="slider-arrow right" onClick={nextSlide}>&gt;</button>
                </div>
            </div>
        </div>
    );
};

export default WhoeverYouAre;
