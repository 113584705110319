import React, { useEffect, useState } from 'react';
import './Header.css';
import logo from './Images/logo.png'; // Adjust the path to your logo image

const Header = () => {
  const [scrolled, setScrolled] = useState(false);
  const [menuOpen, setMenuOpen] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 0) {
        setScrolled(true);
      } else {
        setScrolled(false);
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  return (
    <header className={`header ${scrolled ? 'scrolled' : ''}`}>
      <div className="logo">
        <a href="https://www.veshmn.com/">
          <img src={logo} alt="Logo" />
        </a>
      </div>
      <nav className="nav">
        {/* <a href="#Home" className="nav-link">Home</a> */}
        <a href="#Pricing" className="nav-link">Pricing</a>
        <a href="#Blog" className="nav-link">Blog</a>
        <a href="tel:+918368265309" className="nav-link">Call Us: <span style={{fontSize:'.7rem'}}>+91-8368265309</span></a>
      </nav>
      <div className="hamburger" onClick={toggleMenu}>
        <div />
        <div />
        <div />
      </div>
      <div className={`sidebar ${menuOpen ? 'open' : ''}`}>
        <span className="close-btn" onClick={toggleMenu}>&times;</span>
        {/* <a href="#Home" className="nav-link" onClick={toggleMenu}>Home</a> */}
        <a href="#Pricing" className="nav-link" onClick={toggleMenu}>Pricing</a>
        <a href="#Blog" className="nav-link" onClick={toggleMenu}>Blog</a>
        <a href="tel:+918368265309" className="nav-link" style={{textAlign:'center'}}>Call Us: <br/><span style={{fontSize:'1rem'}}>+91-8368265309</span></a>
      </div>
      <div className={`overlay ${menuOpen ? 'open' : ''}`} onClick={toggleMenu} />
    </header>
  );
};

export default Header;