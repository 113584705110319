import React, { useState, useEffect } from 'react';
import './CustomResidences.css';

const residences = [
  { label: 'Single Family (Duplex / Villa)', key: 'single_family', info: 'A standalone residential unit designed for one family, typically consisting of one or two floors with separate entrances. <br /><strong>Best suited for:</strong> Families seeking privacy and exclusivity, with sufficient space for personal use and potential expansion.' },
  { label: 'Rental Floors', key: 'multi_family_rent', info: 'A multi-level building divided into separate living units, often rented out individually to different families or individuals. <br /><strong>Best suited for:</strong> Investors looking for rental income opportunities or individuals seeking affordable housing options in urban areas.' },
  { label: 'Split Levels', key: 'same_family_different_floors', info: 'A single residence divided vertically to accommodate multiple nuclear families, typically with separate living spaces on each floor. <br /><strong>Best suited for:</strong> Joint families desiring individual living quarters for each nuclear family while maintaining close proximity for familial bonds.' },
  { label: 'Future Rentals', key: 'single_family_with_rental', info: 'A residential unit primarily intended for single-family occupancy but designed with the potential for future rental income through additional units or space. <br /><strong>Best suited for:</strong> Homeowners seeking flexibility and investment potential, considering rental income as a future option to offset expenses.' },
  { label: 'Generation Separation', key: 'joint_family', info: 'A single residence designed to accommodate an extended family, with distinct living spaces allocated to different generations. <br /><strong>Best suited for:</strong> Families valuing tradition and cohesion, aiming to maintain close family ties while providing individual living areas for each generation\'s privacy and comfort.' },
];

const CustomResidences = ({ handleChange, formData }) => {
  const [selectedResidence, setSelectedResidence] = useState('');
  const [floors, setFloors] = useState({
    single_family: 0,
    multi_family_rent: 0,
    same_family_different_floors: 0,
    single_family_with_rental: 0,
    joint_family: 0,
  });
  const [info, setInfo] = useState('');
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const updateIsMobile = () => setIsMobile(window.innerWidth <= 768);
    window.addEventListener('resize', updateIsMobile);
    updateIsMobile();
    return () => window.removeEventListener('resize', updateIsMobile);
  }, []);

  useEffect(() => {
    setFloors({
      single_family: formData.floors?.single_family || 0,
      multi_family_rent: formData.floors?.multi_family_rent || 0,
      same_family_different_floors: formData.floors?.same_family_different_floors || 0,
      single_family_with_rental: formData.floors?.single_family_with_rental || 0,
      joint_family: formData.floors?.joint_family || 0,
    });
    setSelectedResidence(formData.residence_type || '');
  }, [formData]);

  useEffect(() => {
    if (isMobile && info) {
      const handleClickOutside = () => setInfo('');

      document.addEventListener('click', handleClickOutside);
      return () => document.removeEventListener('click', handleClickOutside);
    }
  }, [isMobile, info]);

  const incrementFloor = (event, key) => {
    event.preventDefault();
    setFloors((prevFloors) => {
      if (prevFloors[key] >= 5) {
        return prevFloors; // Return the same state if the value is already 5
      }
      const newFloors = {
        single_family: 0,
        multi_family_rent: 0,
        same_family_different_floors: 0,
        single_family_with_rental: 0,
        joint_family: 0,
        [key]: prevFloors[key] + 1,
      };
      setSelectedResidence(key);
      handleChange({ target: { name: 'num_floors', value: newFloors[key] } });
      handleChange({ target: { name: 'residence_type', value: key } });
      handleChange({ target: { name: 'floors', value: newFloors } });
      return newFloors;
    });
  };

  const decrementFloor = (event, key) => {
    event.preventDefault();
    setFloors((prevFloors) => {
      const newFloors = {
        ...prevFloors,
        [key]: Math.max(0, prevFloors[key] - 1),
      };
      if (newFloors[key] === 0) {
        setSelectedResidence('');
        handleChange({ target: { name: 'residence_type', value: '' } });
      }
      handleChange({ target: { name: 'num_floors', value: newFloors[key] } });
      handleChange({ target: { name: 'floors', value: newFloors } });
      return newFloors;
    });
  };

  const handleInfoClick = (infoText, event) => {
    if (isMobile) {
      event.stopPropagation();
      setInfo(infoText);
    }
  };

  const handleInfoHover = (infoText) => {
    if (!isMobile) {
      setInfo(infoText);
    }
  };

  const handleInfoLeave = () => {
    if (!isMobile) {
      setInfo('');
    }
  };

  return (
    <div className="residences-container">
      {residences.map((residence) => (
        <div key={residence.key} className="residence-row">
          <span className="residence-name">{residence.label}</span>
          <button
            className="info-button"
            onClick={(e) => handleInfoClick(residence.info, e)}
            onMouseEnter={() => handleInfoHover(residence.info)}
            onMouseLeave={handleInfoLeave}
          >
            i
          </button>
          <button
            className="decrement-button"
            onClick={(e) => decrementFloor(e, residence.key)}
            disabled={selectedResidence !== residence.key}
          >
            -
          </button>
          <span className="floor-count">{floors[residence.key]}</span>
          <button
            className="increment-button"
            onClick={(e) => incrementFloor(e, residence.key)}
          >
            +
          </button>
        </div>
      ))}
      {info && (
        <div className="info-box">
          <div className="info-content" dangerouslySetInnerHTML={{ __html: info }}></div>
        </div>
      )}
    </div>
  );
};

export default CustomResidences;
