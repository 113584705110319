import React, { useEffect, useRef, useState } from 'react';
import './NextStepSection.css';

const NextStepSection = () => {
  const [headingVisible, setHeadingVisible] = useState(false);
  const [contentVisible, setContentVisible] = useState(false);
  const sectionRef = useRef(null);

  useEffect(() => {
    const handleScroll = () => {
      const sectionTop = sectionRef.current.getBoundingClientRect().top;
      const windowHeight = window.innerHeight;

      if (sectionTop < windowHeight && sectionTop > 0) {
        setHeadingVisible(true);
        setTimeout(() => {
          setContentVisible(true);
        }, 500); // Delay to show the content after the heading is visible

        window.removeEventListener('scroll', handleScroll);
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <div className="next-step-section" ref={sectionRef}>
      <div className={`next-step-heading ${headingVisible ? 'appear' : ''}`}>
        Next Step
        <span className="highlight"> Stop dreaming. Start building.</span>
      </div>
      <div className={`next-step-content ${contentVisible ? 'appear' : ''}`}>
        The time is now. Start building your dream home with confidence. With our comprehensive kit, you’re just 7 days away from a smooth, hassle-free construction process. Take the decisive step today and turn your vision into reality.
      </div>
    </div>
  );
};

export default NextStepSection;