import React, { useEffect, useState } from "react";
import axios from "axios";
import bedroom from './Image/bedroom.png';
import bath from './Image/bath.png';
import './PropertyDetails.css';
import { useParams, useLocation } from 'react-router-dom';
import DatePicker from 'react-datepicker'; // Import date picker component
import "react-datepicker/dist/react-datepicker.css"; // Import date picker styles
import { useNavigate } from 'react-router-dom';

function Property() {
  const { planId } = useParams();
  const location = useLocation();
  const [data, setData] = useState({});
  const [name, setName] = useState('');
  const [area, setArea] = useState('');
  const [direction, setDirection] = useState('');
  const [propertyData, setPropertyData] = useState({});
  const [selectedDateTime, setSelectedDateTime] = useState(null); // State for selected date and time
  const [isSticky, setIsSticky] = useState(false); // State for sticky behavior
  const navigate = useNavigate();


  useEffect(() => {
    // Make a GET request to fetch the story data
    console.log(planId);
    const formDataFromState = location.state && location.state.formData;
    console.log(formDataFromState);
    setData(formDataFromState);
    if (formDataFromState) {
      setName(formDataFromState.name);
      setArea(formDataFromState.height * formDataFromState.width);
      setDirection(formDataFromState.direction)
    }
    axios.get(`https://www.veshmn.com/api/property-data/${planId}/`)
      .then(response => {
        setPropertyData(response.data);
      })
      .catch(error => {
        console.error('Error fetching story data:', error);
      });

    const handleScroll = () => {
      const propertyElement = document.querySelector('.property-details');
      if (propertyElement) {
        const rect = propertyElement.getBoundingClientRect();
        const isVisible = rect.top < 0 || rect.bottom > window.innerHeight;
        setIsSticky(isVisible);
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [planId, location.state]);

  const handleScheduleClick = () => {
    // Handle scheduling logic here (e.g., send data to server, redirect)
    if (selectedDateTime) {
      const formattedDateTime = selectedDateTime.toLocaleString(); // Format for server consumption
      const postData = {
        scheduled_datetime: formattedDateTime,
        name: data.name, // Assuming data.name is the name of the customer
        mobile: data.mobile, // Assuming data.mobile is the mobile number of the customer
      };

      console.log(postData);

      function getCookie(name) {
        const value = `; ${document.cookie}`;
        const parts = value.split(`; ${name}=`);
        if (parts.length === 2) return parts.pop().split(';').shift();
      }

      const csrftoken = getCookie('csrftoken'); // Define getCookie function
      console.log(csrftoken);
      const config = {
        headers: {
          'Content-Type': 'multipart/form-data',
          'X-CSRFToken': csrftoken // Include CSRF token in headers
        }
      };

      axios.post('https://www.veshmn.com/api/time-schedule/', postData, config)
        .then(response => {
          console.log('Schedule request sent successfully!');
          navigate(`/thankyou`);
          // alert('Schedule request sent successfully!'); 
        })
        .catch(error => {
          console.error('Error sending schedule request:', error);
          alert('Failed to send schedule request. Please try again.'); // Inform user
          document.getElementById('property-card').scrollIntoView({ behavior: 'smooth' });
        });
    } else {
      alert('Please select a date and time for scheduling.');
      document.getElementById('property-card').scrollIntoView({ behavior: 'smooth' });
    }
  };

  return (
    <div className={`card text-white shadow-sm border-0 mb-4 property-details ${isSticky ? 'sticky' : ''}`}>
      <div className="card-body" id="property-card">
      <h1 className="display-5 text-center">{name ? `${name}'s Home` : propertyData.plot_title}</h1>
        <hr />
        <ul className="d-flex justify-content-center align-items-center w-100 mb-3 list-unstyled text-center mt-4">
          <li>
            <h2 className="plot-area">{area ? area : propertyData.plot_covered_area} sqft</h2>
            <h3 className="plot">Plot Area</h3>
          </li>
          {/* <li>
            <h2 className="fs-6 m-0">{propertyData.buildup_area} sqft</h2>
            <small>Build Up Area</small>
           </li> */
          }
          <li>
            {/* <h2 className="fs-6 m-0">{propertyData.floors_count}</h2> */}
            {/* <small>Floors</small> */}
          </li>
        </ul>

        <ul className="d-flex justify-content-center align-items-center w-100 mb-3 list-unstyled text-center mt-5">
          <li className="me-5">
            <img src={bedroom} style={{ borderRadius: '10px', marginBottom: '10px' }} alt="bedroom" className="img-fluid" />
            <small className="bedroom mt-3"> Bedroom {propertyData.bedroom_count}</small>
          </li>
          <li>
            <img src={bath} style={{ borderRadius: '10px', marginBottom: '10px' }} alt="bedroom" className="img-fluid" />
            <small className="bedroom mt-3"> Bathrooms {propertyData.bathroom_count}</small>
          </li>
        </ul>
        <div className="text-center">
          <h6 className="plot-area">Plot Direction: {direction ? direction: propertyData.plot_direction} </h6>
          <h6 className="plot-area">Architectural Style: {propertyData.architectural_style}</h6>
          <p className="plot-area">INR {propertyData.price} /-</p>

          {/* Calendar with time selection */}
          <DatePicker 
            selected={selectedDateTime}
            onChange={date => setSelectedDateTime(date)}
            showTimeSelect
            placeholderText='DD/MM/YYYY HH:MM'
            timeFormat="HH:mm"
            dateFormat="dd/MM/yyyy HH:mm" // Combined date and time format
            className="custom-calendar plot-area" // Add custom CSS class for styling
          />
          <button className={`btn btn-primary ${isSticky ? 'sticky-button' : ''}`} onClick={handleScheduleClick}>Schedule a call</button>
        </div>
      </div>
    </div>
  );
}

export default Property;