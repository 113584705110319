import React, { useState, useEffect } from 'react';
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import { useLocation } from 'react-router-dom';
import undefined_image from './Image/undefined_image.png';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import './UndefinedPage.css';

const UndefinedPage = () => {
  const [data, setData] = useState();
  const [selectedDateTime, setSelectedDateTime] = useState(null);
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    const formDataFromState = location.state && location.state.formData;
    setData(formDataFromState);
  }, [location.state]);

  const handleScheduleClick = () => {
    if (selectedDateTime) {
      const formattedDateTime = selectedDateTime.toLocaleString();
      const postData = {
        scheduled_datetime: formattedDateTime,
        name: data.name,
        mobile: data.mobile,
      };

      function getCookie(name) {
        const value = `; ${document.cookie}`;
        const parts = value.split(`; ${name}=`);
        if (parts.length === 2) return parts.pop().split(';').shift();
    }    


       // Create logic for the Id
       const csrftoken = getCookie('csrftoken'); // Define getCookie function
       // Set headers with CSRF token
       console.log(csrftoken);
       const config = {
           headers: {
               'Content-Type': 'multipart/form-data',
               'X-CSRFToken': csrftoken // Include CSRF token in headers
           }
       };
      axios.post('https://www.veshmn.com/api/time-schedule/', postData, config)
        .then(response => {
          navigate(`/thankyou`);
          console.log('Schedule request sent successfully!');
          // alert('Schedule request sent successfully!');
        })
        .catch(error => {
          console.error('Error sending schedule request:', error);
          alert('Failed to send schedule request. Please try again.');
        });
    } else {
      alert('Please select a date and time for scheduling.');
    }
  };

  return (
    <div className="container-undefined">
      <div className="image-container-undefined">
        <img src={undefined_image} className="img-undefined" alt="img-undefiened" />
      </div>
      <div className="text-container-undefined">
        <h2 style={{fontFamily:'font-family: SF Pro Display, SF Pro Icons, Helvetica Neue, Helvetica, Arial, sans-seri'}} >Your home requires special attention.</h2>
        <p style={{textAlign:'center', fontFamily:'font-family: SF Pro Display, SF Pro Icons, Helvetica Neue, Helvetica, Arial, sans-serif'}}>We will get in touch with you over a call:</p>
        <DatePicker
          selected={selectedDateTime}
          placeholderText='DD/MM/YYYY HH:MM'
          onChange={date => setSelectedDateTime(date)}
          showTimeSelect
          timeFormat="HH:mm"
          dateFormat="dd/MM/yyyy HH:mm"
          className="custom-calendar"
        />
        <button className="btn btn-primary" onClick={handleScheduleClick}>Schedule a call</button>
      </div>
    </div>
  );
}

export default UndefinedPage;