import React, { useState, useEffect } from 'react';
import './Testimonials.css';
import TestiImg1 from './Images/TestiImg1.webp';
import TestiImg2 from './Images/TestiImg2.webp';
import TestiImg3 from './Images/TestiImg3.webp';
import TestiImg4 from './Images/TestiImg4.webp';


const testimonialsData = [
    {
        name: "Ravi Kumar",
        company: "Ghaziabad",
        testimonial: "Veshmn made building our dream home a breeze. Their detailed plans and expert guidance ensured everything was smooth and stress-free.  We couldn't be happier!",
        image: TestiImg1
    },
    {
        name: "Pooja Saini",
        company: "Delhi",
        testimonial: "Veshmn's support was invaluable in building our new home. From start to finish, their attention to detail and professional guidance made all the difference. Truly exceptional!",
        image: TestiImg4
    },
    {
        name: "Priyam Sharma",
        company: "Gurugram",
        testimonial: "Thanks to Veshmn, I was able to build a beautiful home for my parents. The process was seamless, and the quality checks kept everything on track. Highly recommend!",
        image: TestiImg2
    },
    {
        name: "Rakesh Singh",
        company: "Delhi",
        testimonial: "With Veshmn's comprehensive kit, I felt in control of every step. The clear instructions and premium materials made construction a joy, and the end result is perfect.",
        image: TestiImg3
    },
    // Add more testimonials as needed
];

const Testimonials = () => {
    const [currentIndex, setCurrentIndex] = useState(0);
    const [headingVisible, setHeadingVisible] = useState(false);
    const [contentVisible, setContentVisible] = useState(false);

    const nextTestimonial = () => {
        setCurrentIndex((prevIndex) => (prevIndex + 1) % testimonialsData.length);
    };

    useEffect(() => {
        const handleScroll = () => {
            const section = document.querySelector('.testimonials');
            const sectionPosition = section.getBoundingClientRect().top;
            const screenPosition = window.innerHeight / 1.5;

            if (sectionPosition < screenPosition) {
                setHeadingVisible(true);
                setTimeout(() => {
                    setContentVisible(true);
                }, 500); // Delay to show the content after heading is visible
                window.removeEventListener('scroll', handleScroll);
            }
        };

        window.addEventListener('scroll', handleScroll);
        return () => window.removeEventListener('scroll', handleScroll);
    }, []);

    useEffect(() => {
        const interval = setInterval(() => {
            nextTestimonial();
        }, 5000); // Move to the next testimonial every 5 seconds
        return () => clearInterval(interval);
    }, []);

    return (
        <div className="testimonials">
            <h2 className={`testimonials-heading ${headingVisible ? 'appear' : ''}`}>People love us</h2>
            <div className={`testimonials-container ${contentVisible ? 'visible' : ''}`}>
                <div className="testimonials-slider" style={{ transform: `translateX(-${currentIndex * 100}%)` }}>
                    {testimonialsData.map((testimonial, index) => (
                        <div
                            key={index}
                            className="testimonial"
                        >
                            <img src={testimonial.image} alt={testimonial.name} className="testimonial-image" />
                            <div className="testimonial-content">
                                <h3>{testimonial.name}</h3>
                                <h4>{testimonial.company}</h4>
                                <p>{testimonial.testimonial}</p>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
            <div className={`dots ${contentVisible ? 'visible' : ''}`}>
                {testimonialsData.map((_, index) => (
                    <span key={index} className={`dot ${currentIndex === index ? 'active' : ''}`} onClick={() => setCurrentIndex(index)}></span>
                ))}
            </div>
        </div>
    );
};

export default Testimonials;