import React, { useState, useRef, useEffect, useCallback } from 'react';
import './WhyUs.css';
import Informed_control from './Images/Informed_control.png';
import save_big from './Images/save_big.png';
import Energy_efficient from './Images/Energy_efficient.png';
import more_than_bnm from './Images/more_than_bnm.png';
import affordable_excellance from './Images/affordable_excellance.png';
import personalized_design from './Images/personalized_design.png';
import always_here from './Images/always_here.png';
import fastest2 from './Images/fastest2.png';

const data = [
    {
        Icon: Informed_control,
        title: "Informed control",
        text: "Be the boss!",
        modalTitle: "Informed control",
        modalText: "With Veshmn, you have all the information at your fingertips. Keep a check on your contractor and ensure transparency in every step of the construction process."
    },
    {
        Icon: save_big,
        title: "Save big",
        text: "Your wallet will thank you.",
        modalTitle: "Save big",
        modalText: "By choosing Veshmn, you can save at least 2-3 lakh on your construction. Our efficient planning and cost-effective solutions make your dream home affordable."
    },
    {
        Icon: Energy_efficient,
        title: "Energy efficient",
        text: "Keep bills low, live high!",
        modalTitle: "Energy efficient",
        modalText: "Our designs focus on energy efficiency, helping you save on electricity and other utility costs in the long run. Enjoy a home that’s not just beautiful but also economical."
    },
    {
        Icon: more_than_bnm,
        title: "More than bricks",
        text: "Heart and soul in every home",
        modalTitle: "More than bricks and mortar",
        modalText: "A home is more than just bricks and mortar. It’s a space for cherished memories, designed with your profession, tastes, hobbies, and family dynamics in mind."
    },
    {
        Icon: affordable_excellance,
        title: "Affordable excellence",
        text: "Premium quality, qriendly price",
        modalTitle: "Affordable excellence ",
        modalText: "Veshmn offers top-notch architectural services that fit your budget. Experience the best-in-class home design without breaking the bank."
    },
    {
        Icon: personalized_design,
        title: "Personalized designs",
        text: "Your life, your style",
        modalTitle: "Personalized designs",
        modalText: "Your home reflects you. We design homes based on your profession, tastes, hobbies, and family dynamics, ensuring a perfect fit for your lifestyle."
    },
    {
        Icon: always_here,
        title: "Always here for you",
        text: "We’ve got your yack",
        modalTitle: "Always here for you",
        modalText: "We're just a call away for any problem during the construction. Our expert support team is ready to assist you at every step of the way."
    },
    {
        Icon: fastest2,
        title: "Fastest service",
        text: "Blink and It’s done!",
        modalTitle: "Fastest service",
        modalText: "Veshmn provides the fastest architectural service in India, and possibly on the planet. Start your home-building journey without delay and see rapid progress."
    }
];

const WhyUs = () => {
    const [currentIndex, setCurrentIndex] = useState(0);
    const [modalOpen, setModalOpen] = useState(false);
    const [modalContent, setModalContent] = useState({});
    const [headingVisible, setHeadingVisible] = useState(false);
    const [contentVisible, setContentVisible] = useState(false);
    const sliderRef = useRef();
    const touchStartX = useRef(0);
    const isDragging = useRef(false);
    const isDesktop = window.innerWidth >= 768;

    const nextSlide = () => {
        if (isDesktop) {
            const newIndex = (currentIndex + 1) % 3;
            setCurrentIndex(newIndex);
        } else {
            setCurrentIndex((prevIndex) => (prevIndex + 1) % data.length);
        }
    };

    const prevSlide = () => {
        if (isDesktop) {
            const newIndex = (currentIndex - 1 + 3) % 3;
            setCurrentIndex(newIndex);
        } else {
            setCurrentIndex((prevIndex) => (prevIndex - 1 + data.length) % data.length);
        }
    };

    const openModal = (content) => {
        setModalContent({ ...content });
        console.log(content);
        setModalOpen(true);
    };


    const closeModal = () => {
        setModalOpen(false);
    };

    const handleTouchStart = (e) => {
        touchStartX.current = e.touches[0].clientX;
        isDragging.current = true;
    };

    const handleTouchMove = (e) => {
        if (!isDragging.current) return;
        const touchMoveX = e.touches[0].clientX;
        const diffX = touchMoveX - touchStartX.current;

        if (Math.abs(diffX) > 50) {
            if (diffX > 0) {
                prevSlide();
            } else {
                nextSlide();
            }
            isDragging.current = false;
        }
    };

    const handleTouchEnd = () => {
        isDragging.current = false;
    };

    const handleOutsideClick = useCallback((e) => {
        if (e.target.classList.contains('why-us-modal')) {
            closeModal();
        }
    }, []);

    useEffect(() => {
        if (modalOpen) {
            window.addEventListener('click', handleOutsideClick);
        } else {
            window.removeEventListener('click', handleOutsideClick);
        }
        return () => {
            window.removeEventListener('click', handleOutsideClick);
        };
    }, [modalOpen, handleOutsideClick]);

    useEffect(() => {
        const handleScroll = () => {
            const section = document.querySelector('.why-us');
            const sectionPosition = section.getBoundingClientRect().top;
            const screenPosition = window.innerHeight / 1.5;

            if (sectionPosition < screenPosition) {
                setHeadingVisible(true);
                setTimeout(() => {
                    setContentVisible(true);
                }, 500); // Delay to show the content after heading is visible
                window.removeEventListener('scroll', handleScroll);
            }
        };

        window.addEventListener('scroll', handleScroll);
        return () => window.removeEventListener('scroll', handleScroll);
    }, []);

    return (
        <div className="why-us">
            <h2 className={`why-us-heading ${headingVisible ? 'appear' : ''}`}>Why choose Veshmn</h2>
            <div className={`why-us-slider-container ${contentVisible ? 'visible' : ''}`}
                onTouchStart={handleTouchStart}
                onTouchMove={handleTouchMove}
                onTouchEnd={handleTouchEnd}
                ref={sliderRef}
            >
                <div className="why-us-slider" style={{ transform: `translateX(-${currentIndex * 85}%)` }}>
                    {data.map((item, index) => (
                        <div
                            className={`why-us-slide ${index === currentIndex ? 'active' : ''}`}
                            key={index}
                            onClick={() => openModal({ title: item.modalTitle, text: item.modalText, icon: item.Icon })}
                        >
                            <img src={item.Icon} alt={item.title} className="why-us-icon" />
                            <h3>{item.title}</h3>
                            <p>{item.text}</p>
                            <button className="why-us-info-button" onClick={() => openModal({ title: item.modalTitle, text: item.modalText, icon: item.Icon })}>
                                <span className="why-us-plus-icon">+</span>
                            </button>
                        </div>
                    ))}
                </div>
                <div className="why-us-arrow-container">
                    <button className="why-us-slider-arrow left" onClick={prevSlide}>&lt;</button>
                    <button className="why-us-slider-arrow right" onClick={nextSlide}>&gt;</button>
                </div>
            </div>
            {modalOpen && (
                <div className="why-us-modal">
                    <div className="why-us-modal-content">
                        <button className="why-us-close-button" onClick={closeModal}>&times;</button>
                        <div className="modal-header">
                            <div className="modal-text">
                                <h3 className='why-us-modal-content'>{modalContent.title}</h3>
                                <p className='why-us-modal-content'>{modalContent.text}</p>
                            </div>
                            {modalContent.icon && <img src={modalContent.icon} alt={modalContent.title} className="modal-icon" />}
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default WhyUs;