import React, { useEffect, useRef, useState } from 'react';
import './ExpertVisit.css';
import ExpertVisit_Video from './Image/ExpertVisit_Video.mp4';

const ExpertVisit = () => {
  const [isVideoPlaying, setIsVideoPlaying] = useState(false);
  const [headingVisible, setHeadingVisible] = useState(false);
  const [contentVisible, setContentVisible] = useState(false);
  const sectionRef = useRef(null);
  const videoRef = useRef(null);

  useEffect(() => {
    const handleScroll = () => {
      const sectionTop = sectionRef.current.getBoundingClientRect().top;
      const windowHeight = window.innerHeight;

      if (sectionTop < windowHeight && sectionTop > 0) {
        setHeadingVisible(true);
        setTimeout(() => {
          setContentVisible(true);
        }, 500); // Delay to show the content after the heading is visible

        if (!isVideoPlaying) {
          setIsVideoPlaying(true);
          videoRef.current.play();
        }

        window.removeEventListener('scroll', handleScroll);
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [isVideoPlaying]);

  return (
    <div className="expert-visit-section" ref={sectionRef}>
      <h2 className={`expert-visit-heading ${headingVisible ? 'appear' : ''}`}>Expert at doorstep</h2>
      <div className={`expert-visit-content ${contentVisible ? 'appear' : ''}`}>
        <div className="expert-visit-text">
          <p className="desktop-text">Veshmn's experts are always there for you, from start to finish. No matter the challenge, big or small, they’re part of your team, dedicated to making your home-building process seamless and enjoyable.</p>
        </div>
        <div className="epert-video-container">
          <video ref={videoRef} className="expert-video" muted playsInline>
            <source src={ExpertVisit_Video} type="video/mp4" />
            Your browser does not support the video tag.
          </video>
        </div>
      </div>
      <div className={`expert-visit-content mobile-text ${contentVisible ? 'appear' : ''}`}>
        <p>Veshmn's experts are always there for you, from start to finish. No matter the challenge, big or small, they’re part of your team, dedicated to making your home-building process seamless and enjoyable.</p>
      </div>
    </div>
  );
};

export default ExpertVisit;
