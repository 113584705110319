import React, { useEffect, useRef, useState } from 'react';
import './WhatsInside.css';

const WhatsInside = () => {
    const [showPaper, setShowPaper] = useState(false);
    const [showText, setShowText] = useState(false);
    const [hasWritten, setHasWritten] = useState(false);
    const [text, setText] = useState('');
    const [headingVisible, setHeadingVisible] = useState(false);
    const [contentVisible, setContentVisible] = useState(false);
    const sectionRef = useRef(null);
    const fullText = "Floor plan\nElevation drawings\nSection drawings\nElectrical drawings\nPlumbing drawings\nStructural drawings (Civil drawings) \nMaterial quantity\nMaterial samples\nInterior designs\nInterior mood board\nProject timelines\nCost estimate\nChecklist for entire work";

    useEffect(() => {
        const handleScroll = () => {
            const sectionTop = sectionRef.current.getBoundingClientRect().top;
            const windowHeight = window.innerHeight;
            if (sectionTop < windowHeight && sectionTop > 0 && !hasWritten) {
                setHeadingVisible(true);
                setTimeout(() => {
                    setContentVisible(true);
                    setTimeout(() => {
                        setShowPaper(true);
                        setTimeout(() => {
                            setShowText(true);
                            setHasWritten(true); // Set the state to true to prevent further writing
                        }, 500); // Adjust delay to sync with paper appearance
                    }, 500); // Delay to show the content after heading is visible
                }, 1000); // Delay to show the heading
                window.removeEventListener('scroll', handleScroll);
            }
        };

        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, [hasWritten]);

    useEffect(() => {
        if (showText) {
            let index = 0;
            const interval = setInterval(() => {
                if (index < fullText.length) {
                    setText(fullText.slice(0, index + 1));
                    index++;
                } else {
                    clearInterval(interval);
                }
            }, 75); // Adjust typing speed
        }
    }, [showText]);

    return (
        <div className="whats-inside-section" ref={sectionRef}>
            <h2 className={`whats-inside-heading ${headingVisible ? 'appear' : ''}`}>Writing what you get</h2>
            <div className={`whats-inside-content ${contentVisible ? 'appear' : ''}`}>
                <div className="whats-inside-text">
                    <p className="whats-inside-paragraph">Veshmn kit has everything you need to build your dream home. With easy-to-follow guides and material samples, we provide all the tools and information for a smooth construction experience. Build with confidence and peace of mind.</p>
                </div>
                <div className={`ruled-paper ${showPaper ? 'appear' : ''}`}>
                    <div className="typewriter-text">
                        {text}
                        {showText && <span className="cursor"></span>}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default WhatsInside;
