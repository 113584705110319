import React, { useEffect, useState } from 'react';
import './HowItWorks.css';
import step1Icon from './Images/Step_1.png';
import step2Icon from './Images/Step_2.png';
import step3Icon from './Images/Step_3.png';
import step4Icon from './Images/Step_4.png';

const HowItWorks = () => {
    const [headingVisible, setHeadingVisible] = useState(false);
    const [contentVisible, setContentVisible] = useState(false);

    useEffect(() => {
        const handleScroll = () => {
            const section = document.querySelector('.how-it-works');
            const sectionPosition = section.getBoundingClientRect().top;
            const screenPosition = window.innerHeight / 1.5;

            if (sectionPosition < screenPosition) {
                setHeadingVisible(true);
                setTimeout(() => {
                    setContentVisible(true);
                }, 500); // Delay to show the content after heading is visible
                window.removeEventListener('scroll', handleScroll);
            }
        };
        
        window.addEventListener('scroll', handleScroll);
        return () => window.removeEventListener('scroll', handleScroll);
    }, []);

    useEffect(() => {
        if (contentVisible) {
            const observerOptions = {
                threshold: 0.5, // Adjust this threshold as needed
            };

            const observerCallback = (entries, observer) => {
                entries.forEach(entry => {
                    if (entry.isIntersecting) {
                        const steps = document.querySelectorAll('.step');
                        const lines = document.querySelectorAll('.line');
                        let delay = 0;
                        steps.forEach((step, index) => {
                            setTimeout(() => {
                                step.classList.add('pop');
                                if (lines[index]) {
                                    setTimeout(() => {
                                        lines[index].classList.add('draw');
                                    }, 500); // Control the delay between icon pop and line drawing here
                                }
                            }, delay);
                            delay += 1500; // Control the delay between each step animation here
                        });

                        observer.unobserve(entry.target);
                    }
                });
            };

            const observer = new IntersectionObserver(observerCallback, observerOptions);

            const howItWorksSection = document.querySelector('.how-it-works');
            if (howItWorksSection) {
                observer.observe(howItWorksSection);
            }

            return () => {
                if (howItWorksSection) {
                    observer.unobserve(howItWorksSection);
                }
            };
        }
    }, [contentVisible]);

    return (
        <div className="how-it-works">
            <h2 className={`heading ${headingVisible ? 'appear' : ''}`}>The journey begins here</h2>
            <div className={`steps-container ${contentVisible ? 'visible' : ''}`}>
                <div className="step">
                    <img src={step1Icon} alt="Step 1" className="step-icon" />
                    <p><span style={{ fontWeight: "bold" }}>Share your vision:</span> Tell us your dreams and desires for your perfect home.</p>
                    <div className="line line1"></div>
                </div>
                <div className="step">
                    <img src={step2Icon} alt="Step 2" className="step-icon" />
                    <p> <span style={{ fontWeight: "bold" }}>Explore in 3D:</span> Walk through your future home and personalize it.
                    </p>
                    <div className="line line2"></div>
                </div>
                <div className="step">
                    <img src={step3Icon}  alt="Step 3" className="step-icon" />
                    <p style={{ marginRight: '50px' }}> <span style={{ fontWeight: "bold" }}>Get your kit:</span> Receive detailed information and material samples for your home construction.</p>
                    <div className="line line3">  </div>
                </div>
                <div className="step">
                    <img src={step4Icon} alt="Step 4" className="step-icon" />
                    <p> <span style={{ fontWeight: "bold" }}>Build your home:</span> Take charge, minimize waste, and get a hassele-free construction.</p>
                </div>
            </div>
        </div>
    );
};

export default HowItWorks;