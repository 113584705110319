import React, { useState } from 'react';
import axios from 'axios';
import CustomDropdown from './CustomDropdown';
import CustomResidences from './CustomResidences';
import CustomResidents from './CustomResidents';
import './formfirst.css';
import { useNavigate } from 'react-router-dom';

const DreamHomeForm = () => {
  const [step, setStep] = useState(1);
  const [formData, setFormData] = useState({
    name: '',
    mobile: '',
    pincode: '',
    width: '',
    height: '',
    is_rectangular: false,
    is_corner: false,
    direction: '',
    residence_type: '',
    num_floors: 0,
    is_stilt: false,
    planning_preferences: '',
    room_privacy: '',
    vastu: '',
    lift: '',
    num_adults: 0,
    num_old_adults: 0,
    num_middle_adults: 0,
    num_teenagers: 0,
    num_kids: 0,
    floors: {}
  });
  const [errors, setErrors] = useState({});
  const [cardError, setCardError] = useState(false);
  const [animating, setAnimating] = useState(false);
  const navigate = useNavigate();

  const validateFields = (currentStep) => {
    let newErrors = {};
    if (currentStep === 1) {
      if (!formData.name) newErrors.name = 'Name is required';
      if (!formData.mobile) newErrors.mobile = 'Mobile number is required';
      if (formData.mobile && formData.mobile.length < 10) newErrors.mobile = 'Mobile number must be at least 10 digits';
      if (!formData.pincode) newErrors.pincode = 'Pincode is required';
    }
    if (currentStep === 2) {
      if (!formData.width) newErrors.width = 'Width is required';
      if (!formData.height) newErrors.height = 'Height is required';
      if (!formData.direction) newErrors.direction = 'Direction is required';
    }
    if (currentStep === 4) {
      if (!formData.planning_preferences) newErrors.planning_preferences = 'Planning preference is required';
      if (!formData.room_privacy) newErrors.room_privacy = 'Room privacy is required';
      if (!formData.vastu) newErrors.vastu = 'Vastu preference is required';
      if (!formData.lift) newErrors.lift = 'Lift preference is required';
    }
    if (currentStep === 5) {
      const totalResidents = formData.num_adults + formData.num_old_adults + formData.num_middle_adults + formData.num_teenagers + formData.num_kids;
      if (totalResidents === 0) {
        newErrors.residents = 'At least one resident type must be specified';
      }
    }
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const validateCard = (currentStep) => {
    if (currentStep === 3) {
      if (formData.num_floors === 0) {
        setCardError(true);
        return false;
      }
    }
    setCardError(false);
    return true;
  };

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    console.log(name, value);

    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: name === 'num_floors' ? parseInt(value, 10) : (type === 'checkbox' ? checked : value)
    }));

    setErrors((prevErrors) => ({
      ...prevErrors,
      [name]: ''
    }));

    setCardError(false);
  };

  const handleNext = () => {
    if (validateFields(step) && validateCard(step)) {
      if (step < 5) {
        setAnimating(true);
        setTimeout(() => {
          setAnimating(false);
          setStep(step + 1);
        }, 500);
      }
    } else {
      setAnimating(false);
    }
  };

  const handlePrev = () => {
    if (step > 1) {
      setAnimating(true);
      setTimeout(() => {
        setAnimating(false);
        setStep(step - 1);
      }, 500);
    }
  };

  function getCookie(name) {
    const value = `; ${document.cookie}`;
    const parts = value.split(`; ${name}=`);
    if (parts.length === 2) return parts.pop().split(';').shift();
}

  const handleSubmit = async (e, personalize = false) => {
    e.preventDefault();
    if (validateFields(step)) {
      try {
        const csrftoken = getCookie('csrftoken'); // Define getCookie function
        // Set headers with CSRF token
        console.log(csrftoken);
        const config = {
            headers: {
                'Content-Type': 'multipart/form-data',
                'X-CSRFToken': csrftoken // Include CSRF token in headers
            }
        };

        const response = await axios.post('https://www.veshmn.com/api/Dream-Home/', formData, config);
        console.log('Response:', response.data);
        if (personalize) {
          navigate(`/custom-requirement`, { state: { formData: formData, id: response.data.id } });
        } else {
          let area_range;
          const area = formData.width * formData.height;
          console.log(area);
          if (area <= 900) area_range = 1;
          if (area > 900 && area <= 1350) area_range = 2;
          if (area > 1350 && area <= 1575) area_range = 3;
          if (area > 1575 && area <= 1800) area_range = 4;
          if (area > 1800 && area <= 2250) area_range = 5;
          if (area > 2250 && area <= 2475) area_range = 6;
          if (area > 2475 && area <= 2700) area_range = 7;
          if (area > 2700) area_range = 8;
          const url = `https://www.veshmn.com/api/pixi-data/`;
          console.log(area_range);
          fetch(url)
            .then(response => response.json())
            .then(data => {
              const matchingObject = data.filter(item => item.area_range === area_range);
              console.log(matchingObject);
              let maxMatchingObject = matchingObject[0];
              let maxMatchCount = 0;
              matchingObject.forEach(item => {
                let matchCount = 0;
                if (item.direction === formData.direction) matchCount++;
                if (item.lift === formData.lift) matchCount++;
                if (item.vastu === formData.vastu) matchCount++;
                if (item.residence_type === formData.residence_type) matchCount++;
                if (matchCount > maxMatchCount) {
                  maxMatchCount = matchCount;
                  maxMatchingObject = item;
                }
              });
              console.log(maxMatchingObject);
              if (maxMatchingObject) {
                navigate(`/model/${maxMatchingObject.id}/`, { state: { formData: formData } });
              } else {
                console.error('No matching data found');
                navigate(`/model/special-requirement/`, { state: { formData: formData } });
              }
            })
        }
      } catch (error) {
        console.error('Error submitting data:', error);
      }
    }
  };

  const progressPercentage = Math.round((step / 5) * 100);

  const getStepHeading = () => {
    switch (step) {
      case 1:
        return 'Contact Details';
      case 2:
        return 'Plot Details';
      case 3:
        return 'Residence Type - Add Floors';
      case 4:
        return 'Planning Preferences';
      case 5:
        return 'Number of Residents';
      default:
        return 'Tell Us About Your Dream Home';
    }
  };

  return (
    <div className="container-wrapper">
      <div className="container-first">
        <div className="progress-bar">
          <div style={{ width: `${progressPercentage}%` }}>{progressPercentage}%</div>
        </div>
        <h1 className="form-heading">{getStepHeading()}</h1>
        <form onSubmit={(e) => handleSubmit(e)}>
          <div className={`card-start ${animating ? 'card-exit' : ''}`}>
            {step === 1 && (
              <div>
                <div className={`form-group ${errors.name ? 'invalid' : ''}`}>
                  <label>Name</label>
                  <input type="text" name="name" value={formData.name} placeholder="Enter your name" onChange={handleChange} />
                  {errors.name && <span className="error-message">{errors.name}</span>}
                </div>
                <div className={`form-group ${errors.mobile ? 'invalid' : ''}`}>
                  <label>Mobile Number</label>
                  <input
                    type="tel"
                    name="mobile"
                    value={formData.mobile}
                    placeholder="Enter your mobile number"
                    onChange={handleChange}
                    onInput={(e) => {
                      e.target.value = e.target.value.replace(/[^0-9]/g, '').slice(0, 13);
                    }}
                  />
                  {errors.mobile && <span className="error-message">{errors.mobile}</span>}
                </div>
                <div className={`form-group ${errors.pincode ? 'invalid' : ''}`}>
                  <label>Pincode</label>
                  <input
                    type="text"
                    name="pincode"
                    value={formData.pincode}
                    placeholder="Enter your pincode"
                    onChange={handleChange}
                    onInput={(e) => {
                      e.target.value = e.target.value.replace(/[^0-9]/g, '').slice(0, 10);
                    }}
                  />
                  {errors.pincode && <span className="error-message">{errors.pincode}</span>}
                </div>
              </div>
            )}
            {step === 2 && (
              <div>
                <div className={`form-group ${errors.width ? 'invalid' : ''}`}>
                  <label>Width (in ft)</label>
                  <input type="number" name="width" value={formData.width} placeholder="Enter width in ft" onChange={handleChange} />
                  {errors.width && <span className="error-message">{errors.width}</span>}
                </div>
                <div className={`form-group ${errors.height ? 'invalid' : ''}`}>
                  <label>Length (in ft)</label>
                  <input type="number" name="height" value={formData.height} placeholder="Enter length in ft" onChange={handleChange} />
                  {errors.height && <span className="error-message">{errors.height}</span>}
                </div>
                <div className="form-group">
                  <label>My plot is not rectangular.</label>
                  <input type="checkbox" className='form-check-input' name="is_rectangular" style={{ width: '1em', height: '1em', border: '1px solid #7a7979', marginLeft: '20px' }} checked={formData.is_rectangular} onChange={handleChange} />
                </div>
                <div className="form-group">
                  <label>I have a corner plot.</label>
                  <input type="checkbox" className='form-check-input' name="is_corner" style={{ width: '1em', height: '1em', border: '1px solid #7a7979', marginLeft: '20px' }} checked={formData.is_corner} onChange={handleChange} />
                </div>
                <div className="form-group">
                  <label>I need a stilt floor.</label>
                  <input type="checkbox" className='form-check-input' name="is_stilt" style={{ width: '1em', height: '1em', border: '1px solid #7a7979', marginLeft: '20px' }} checked={formData.is_stilt} onChange={handleChange} />
                </div>
                <div className={`form-group ${errors.direction ? 'invalid' : ''}`}>
                  <CustomDropdown
                    label="Plot Facing Direction"
                    name="direction"
                    options={[
                      { value: 'east', label: 'East', shortLabel: 'East' },
                      { value: 'west', label: 'West', shortLabel: 'West' },
                      { value: 'north', label: 'North', shortLabel: 'North' },
                      { value: 'south', label: 'South', shortLabel: 'South' }
                    ]}
                    handleChange={handleChange}
                    value={formData.direction}
                  />
                  {errors.direction && <span className="error-message">{errors.direction}</span>}
                </div>
              </div>
            )}
            {step === 3 && (
              <div className={errors.residence_type || cardError ? 'invalid' : ''}>
                <CustomResidences handleChange={handleChange} formData={formData} />
                {(errors.residence_type || cardError) && <span className="error-message">Please select at least one residence type and number of floors.</span>}
              </div>
            )}
            {step === 4 && (
              <div>
                <div className={`form-group ${errors.planning_preferences ? 'invalid' : ''}`}>
                  <CustomDropdown
                    label="Planning Preferences"
                    name="planning_preferences"
                    options={[
                      { value: 'closed', label: 'Radial Planning - Many rooms arranged around a center. Ideal for those needing multiple rooms like bedrooms, studies, or store rooms.', shortLabel: 'Radial Planning' },
                      { value: 'open', label: 'Linear Planning - Open spaces in a straight line with fewer walls. Perfect for those valuing modern, airy spaces with natural light.', shortLabel: 'Linear Planning' }
                    ]}
                    handleChange={handleChange}
                    value={formData.planning_preferences}
                  />
                  {errors.planning_preferences && <span className="error-message">{errors.planning_preferences}</span>}
                </div>
                <div className={`form-group ${errors.room_privacy ? 'invalid' : ''}`}>
                  <CustomDropdown
                    label="Room Privacy"
                    name="room_privacy"
                    options={[
                      { value: 'all', label: 'Full Privacy - Ensures privacy in all rooms, including bedrooms. Ideal for those valuing privacy throughout their home with clear zoning for different spaces.', shortLabel: 'Full Privacy' },
                      { value: 'guest', label: 'Partial Privacy - Privacy in all rooms except the guest room. Suitable for those who want privacy in most areas but prefer an open guest space.', shortLabel: 'Partial Privacy' },
                      { value: 'none', label: 'No Privacy Focus - Open and interconnected layout. Best for those prioritizing a fluid, communal living environment over privacy.', shortLabel: 'No Privacy' }
                    ]}
                    handleChange={handleChange}
                    value={formData.room_privacy}
                  />
                  {errors.room_privacy && <span className="error-message">{errors.room_privacy}</span>}
                </div>
                <div className={`form-group ${errors.vastu ? 'invalid' : ''}`}>
                  <CustomDropdown
                    label="Vastu Preference"
                    name="vastu"
                    options={[
                      { value: 'fully', label: 'Fully Vastu - Strictly follows all Vastu rules. Ideal for those strongly believing in Vastu principles.', shortLabel: 'Fully Vastu' },
                      { value: 'basic', label: 'Basic Vastu - Simple Vastu rules with some flexibility. Best for those who like Vastu but also want practicality.', shortLabel: 'Basic Vastu' },
                      { value: 'not_concern', label: 'No Vastu - Design based on personal preferences and needs. Suitable for those focusing on what they like and need.', shortLabel: 'No Vastu' }
                    ]}
                    handleChange={handleChange}
                    value={formData.vastu}
                  />
                  {errors.vastu && <span className="error-message">{errors.vastu}</span>}
                </div>
                <div className={`form-group ${errors.lift ? 'invalid' : ''}`}>
                  <CustomDropdown
                    label="Lift Preference"
                    name="lift"
                    options={[
                      { value: 'needed', label: 'Lift Needed - Ensures easy movement for those with mobility issues. Ideal for families with members who have difficulty moving between floors.', shortLabel: 'Lift Needed' },
                      { value: 'provision', label: 'Lift Provision - Future-ready for adding a lift if needed. Suitable for those who want the option for a lift in the future.', shortLabel: 'Lift Provision' },
                      { value: 'not_needed', label: 'No Lift - Not required. Best for families without mobility issues.', shortLabel: 'No Lift' }
                    ]}
                    handleChange={handleChange}
                    value={formData.lift}
                  />
                  {errors.lift && <span className="error-message">{errors.lift}</span>}
                </div>
              </div>
            )}
            {step === 5 && (
              <div className={errors.residents ? 'invalid' : ''}>
                <CustomResidents handleChange={handleChange} formData={formData} />
                {errors.residents && <span className="error-message">At least one resident type must be specified</span>}
              </div>
            )}
          </div>
          <div className="button-container">
            {step > 1 && <button type="button" onClick={handlePrev}>Back</button>}
            {step < 5 && <button type="button" className="button-next" onClick={handleNext}>Next</button>}
            {step === 5 && (
              <>
                <button type="button" onClick={(e) => handleSubmit(e, true)}>Personalize</button>
                <button type="submit">Submit</button>
              </>
            )}
          </div>
        </form>
      </div>
    </div>
  );
};

export default DreamHomeForm;