import React from 'react';
import './Planbutton.css';
import ThreeD from './Image/ThreeD.png';
import TwoD from './Image/TwoD.png';

const Planbutton = ({ setIsThreeD }) => { 

    // Accept setIsThreeD prop
    // Function to set isThreeD state to true
    const handleThreeDClick = () => {
        setIsThreeD(true);
    };
    // Function to set isThreeD state to false
    const handleTwoDClick = () => {
        setIsThreeD(false);
    };
    return (
        <div className="Planbutton-1 mt-0">
            <div className="column">
                <img
                    src={ThreeD}
                    alt="3D Model"
                    className="dropdown-img"
                    onClick={handleThreeDClick} // Call handleThreeDClick on click
                />
            </div>
            <div className="column">
                <img
                    src={TwoD}
                    alt="2D Model"
                    className="dropdown-img"
                    onClick={handleTwoDClick} // Call handleTwoDClick on click
                />
            </div>
        </div>
    );
};

export default Planbutton;
