import React, { useState, useEffect } from 'react';
import './WhatsInsideKit.css';
import closedBox from './Images/closed-box.png';
import openBox from './Images/open-box.png';
import { FaChevronDown } from 'react-icons/fa';

const WhatsInsideKit = () => {
    const [boxOpen, setBoxOpen] = useState(false);
    const [itemsVisible, setItemsVisible] = useState(false);
    const [expandedIndex, setExpandedIndex] = useState(null);
    const [headingVisible, setHeadingVisible] = useState(false);
    const [contentVisible, setContentVisible] = useState(false);

    useEffect(() => {
        const handleScroll = () => {
            const section = document.querySelector('.whats-inside-kit');
            const sectionPosition = section.getBoundingClientRect().top;
            const screenPosition = window.innerHeight / 1.5;

            if (sectionPosition < screenPosition && !headingVisible) {
                setHeadingVisible(true);
                setTimeout(() => {
                    setContentVisible(true);
                }, 500); // Delay to show the content after heading is visible
                window.removeEventListener('scroll', handleScroll);
            }
        };
        window.addEventListener('scroll', handleScroll);
        return () => window.removeEventListener('scroll', handleScroll);
    }, [headingVisible]);

    useEffect(() => {
        if (contentVisible) {
            const handleScroll = () => {
                const section = document.querySelector('.whats-inside-kit');
                const sectionPosition = section.getBoundingClientRect().top;
                const screenPosition = window.innerHeight / 1.5;

                if (sectionPosition < screenPosition && !boxOpen) {
                    setBoxOpen(true);
                    setTimeout(() => {
                        const box = document.querySelector('.box-image');
                        setTimeout(() => {
                            setTimeout(() => {
                                box.style.transform = 'rotate(30deg)';
                                box.src = openBox;
                                setItemsVisible(true);
                            }, 1000); // Delay to show items after the box opens
                        }, 1000); // Time for the rotation effect
                    }, 500); // Initial delay before starting the rotation
                }
            };
            window.addEventListener('scroll', handleScroll);
            return () => window.removeEventListener('scroll', handleScroll);
        }
    }, [contentVisible, boxOpen]);

    const handleItemClick = (index) => {
        setExpandedIndex((prevIndex) => (prevIndex === index ? null : index));
    };

    const items = [
        {
            title: "Architectural drawing (floor plan, elevation, and more)",
            details: "These detailed plans and drawings ensure every dimension is accurate and minimising errors on-site, so the home is built exactly as imagined."
        },
        {
            title: "MEP drawing (electrical, plumbing, and more)",
            details: "These layouts for electrical and plumbing systems help in fixing issues easily and ensure independent systems, preventing cascading problems."
        },
        {
            title: "Structural drawings",
            details: "Dimensions for foundations, columns, and beams guarantee a home's safety against earthquakes and other disasters, ensuring peace of mind."
        },
        {
            title: "Elevation drawings",
            details: "They provide exact dimensions and materials for the home’s exterior, ensuring it matches the vision and enhances curb appeal."
        },
        {
            title: "Material quantity",
            details: "This list keeps one in control of their budget and ensures the material is ordered in  right quantity, avoiding wastage and shortages."
        },
        {
            title: "Material samples",
            details: "Samples of non-branded materials like bricks and aggregates help in verifying quality, ensuring the home is built with the best."
        },
        {
            title: "Interior designs and mood board",
            details: "Details on themes and material sources align interiors with the taste, guiding on where to procure the material for the dream home."
        },
        {
            title: "Project timelines",
            details: "Standard times for each construction stage helps in tracking progress, identify delays, and planning the move-in date efficiently."
        },
        {
            title: "Cost estimate",
            details: "A detailed cost breakdown provides complete transparency, allowing one to plan their budget and avoid financial surprises."
        },
        {
            title: "Execution checklist (400+ quality checks)",
            details: "Over 400 quality checks ensure everything on-site is done correctly and safely, giving confidence in all the work being done."
        }
    ];

    return (
        <div className="whats-inside-kit">
            <h2 className={`kit-heading ${headingVisible ? 'appear' : ''}`}>Get the complete kit</h2>
            <p className={`kit-description ${contentVisible ? 'visible' : ''}`}>
                Veshmn kit includes everything one needs to start building their dream home. From detailed guides to material samples, Veshmn provides all the tools and information necessary for a seamless construction experience.            </p>
            <div className={`kit-content ${contentVisible ? 'visible' : ''}`}>
                <div className="kit-box">
                    <img src={closedBox} alt="Kit box" className="box-image" />
                </div>
                <div className={`kit-items ${itemsVisible ? 'visible' : ''}`}>
                    {items.map((item, index) => (
                        <div className="kit-item-container" key={index}>
                            <div className="kit-item" onClick={() => handleItemClick(index)}>
                                <span>{item.title}</span>
                                <FaChevronDown className={`chevron-icon ${expandedIndex === index ? 'expanded' : ''}`} />
                            </div>
                            {expandedIndex === index && (
                                <div className="item-details">
                                    <p>{item.details}</p>
                                </div>
                            )}
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
};

export default WhatsInsideKit;
