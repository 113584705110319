import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useParams } from 'react-router-dom';

import './BlogDetail.css';

const BlogDetail = () => {
    const { id } = useParams();
    const [blog, setBlog] = useState(null);

    useEffect(() => {
        axios.get(`https://www.veshmn.com/api/blog/${id}/`)
            .then(response => {
                setBlog(response.data);
            })
            .catch(error => {
                console.error('Error fetching blog:', error);
            });
    }, [id]);

    if (!blog) {
        return <div>Loading...</div>;
    }

    return (
        <div className="blog-detail" dangerouslySetInnerHTML={{ __html: blog.content }}></div>
    );
};

export default BlogDetail;

