import React, { useState, useRef, useEffect, useCallback } from 'react';
import './ImageCarousel.css';
import Card_1 from './Image/Card_1.webp';
import Model_1 from './Image/Modal_1.jpg';
import Card_2 from './Image/Card_2.webp';
import Model_2 from './Image/Modal_2.webp'
import Card_3 from './Image/Card_3.webp';
import Model_3 from './Image/Modal_3.webp';
import Card_4 from './Image/Card_4.webp';
import Model_4 from './Image/Modal_4.png';
import Card_5 from './Image/Card_5.webp';
import Model_5 from './Image/Modal_5.webp';


const data = [
    {
        Icon: Card_1,
        title: "Enjoy a Smooth Build",
        modalTitle: "Enjoy a Smooth Build",
        modalImage: Model_1,
        modalText: (
            <>
                <span style={{ color: '#00b1ff', fontWeight: 'bold' }}>Say goodbye to construction stress.</span> Our comprehensive kit has everything meticulously planned in an easy-to-understand manner. This means you can relax, maintain your daily routine, and still have time for work and family. Experience a <span style={{ color: '#00b1ff', fontWeight: 'bold' }}>joyous, hassle-free construction</span> process, with Veshmn, knowing everything is taken care of.
            </>
        ),
        overlayClass: "card1-overlay", // Add this field
    },
    {
        Icon: Card_2,
        title: "Stay on Budget",
        modalTitle: "Stay on Budget",
        modalImage: Model_2,
        modalText: (
            <>
                Prevent unexpected expenses with detailed material lists and cost estimates. Veshmn's precise planning <span style={{ color: '#108200', fontWeight: 'bold' }}>avoids unexpected errors and delays, </span> Happily count your saved money while enjoying a <span style={{ color: '#108200', fontWeight: 'bold' }}> smooth construction process. </span>
            </>
        ),
        overlayClass: "card2-overlay"
    },
    {
        Icon: Card_3,
        title: "Empower Your Build",
        modalTitle: "Empower Your Build",
        modalImage: Model_3,
        modalText: (
            <>
                <span style={{ color: '#0068ff', fontWeight: 'bold' }}>Take charge with confidence.</span>The kit provides all the information and quality checks to keep your project on track. With complete control over drawings and checklists, <span style={{ color: '#0068ff', fontWeight: 'bold' }}> no one can fool you. </span> Veshmn ensures you have the power to oversee your construction effectively and confidently.
            </>
        ),
        overlayClass: "card3-overlay"
    },
    {
        Icon: Card_4,
        title: "On-Time Completion",
        modalTitle: "On-Time Completion",
        modalImage: Model_4,
        modalText: (
            <>
                <span style={{ color: '#108200', fontWeight: 'bold' }}>Avoid frustrating delays</span> with our comprehensive project timelines. Know what to expect at each stage, keeping your construction on schedule. <span style={{ color: '#108200', fontWeight: 'bold' }}>Plan your move-in </span> with confidence, knowing everything is on track.  <span style={{ color: '#108200', fontWeight: 'bold' }}>Enjoy a smooth, timely building process </span> with Veshmn.
            </>
        ),
        overlayClass: "card4-overlay"
    },
    {
        Icon: Card_5,
        title: "Top-Quality Materials",
        modalTitle: "Top-Quality Materials",
        modalImage: Model_5,
        modalText: (
            <>
                <span style={{ color: '#ff0049', fontWeight: 'bold' }} >Build with the best.</span> The kit ensures premium quality and durability with <span style={{ color: '#ff0049', fontWeight: 'bold' }}> material samples and thorough checklists. </span> Get the right materials at the right price and low-maintenance cost. Experience confidence with Veshmn, using the best materials on the market.
            </>
        ),
        overlayClass: "card5-overlay"
    }
];

const ImageCarousel = () => {
    const [currentIndex, setCurrentIndex] = useState(0);
    const [modalOpen, setModalOpen] = useState(false);
    const [modalContent, setModalContent] = useState({});
    const [headingVisible, setHeadingVisible] = useState(false);
    const [contentVisible, setContentVisible] = useState(Array(data.length).fill(false));
    const sliderRef = useRef();
    const touchStartX = useRef(0);
    const isDragging = useRef(false);
    const isDesktop = window.innerWidth >= 768;

    useEffect(() => {
        const handleScroll = () => {
            const sliderTop = sliderRef.current.getBoundingClientRect().top;
            const sliderHeight = sliderRef.current.clientHeight;
            const windowHeight = window.innerHeight;

            if (sliderTop <= windowHeight && sliderTop + sliderHeight >= 0) {
                setTimeout(() => setHeadingVisible(true), 500); // Delay for heading appear

                // Sequentially show content with delay
                data.forEach((_, index) => {
                    setTimeout(() => {
                        setContentVisible(prevState => {
                            const newState = [...prevState];
                            newState[index] = true;
                            return newState;
                        });
                    }, 500 + index * 300); // Control delay for each card here
                });
            }
        };

        window.addEventListener('scroll', handleScroll);
        return () => window.removeEventListener('scroll', handleScroll);
    }, []);

    const handleNext = useCallback(() => {
        if (isDesktop) {
            const newIndex = (currentIndex + 1) % 3;
            setCurrentIndex(newIndex);
        } else {
            setCurrentIndex((prevIndex) => (prevIndex + 1) % data.length);
        }
    }, [currentIndex, isDesktop]);

    const handlePrev = useCallback(() => {
        if (isDesktop) {
            const newIndex = (currentIndex - 1 + 3) % 3;
            setCurrentIndex(newIndex);
        } else {
            setCurrentIndex((prevIndex) => (prevIndex - 1 + data.length) % data.length);
        }
    }, [currentIndex, isDesktop]);

    const openModal = (content) => {
        setModalContent(content);
        setModalOpen(true);
    };

    const closeModal = () => {
        setModalOpen(false);
    };

    const handleModalClick = (e) => {
        if (e.target.classList.contains('image-carousel-modal')) {
            closeModal();
        }
    };

    const handleTouchStart = (e) => {
        touchStartX.current = e.touches[0].clientX;
        isDragging.current = true;
    };

    const handleTouchMove = (e) => {
        if (isDragging.current) {
            const touchCurrentX = e.touches[0].clientX;
            if (touchCurrentX - touchStartX.current > 50) {
                handlePrev();
                isDragging.current = false;
            } else if (touchCurrentX - touchStartX.current < -50) {
                handleNext();
                isDragging.current = false;
            }
        }
    };

    const handleTouchEnd = () => {
        isDragging.current = false;
    };

    const sliderStyle = {
        transform: `translateX(-${currentIndex * (isDesktop ? 33.33 : 86)}%)`,
    };

    return (
        <div className="image-carousel">
            <h2 className={`image-carousel-heading ${headingVisible ? 'appear' : ''}`}>Easiest way to build your home</h2>
            <div
                className={`image-carousel-slider-container ${contentVisible.every(visible => visible) ? 'visible' : ''}`}
                ref={sliderRef}
                onTouchStart={handleTouchStart}
                onTouchMove={handleTouchMove}
                onTouchEnd={handleTouchEnd}
            >
                <div className="image-carousel-slider" style={sliderStyle}>
                    {data.map((slide, index) => (
                        <div
                            key={index}
                            className={`image-carousel-slide ${slide.overlayClass ? slide.overlayClass : ''} ${contentVisible[index] ? 'active' : ''}`}
                            onClick={() => openModal(slide)}
                        >
                            <div className="image-carousel-title">{slide.title}</div>
                            <img src={slide.Icon} alt={slide.title} className="image-carousel-icon" />
                            <button className="image-carousel-info-button" onClick={(e) => { e.stopPropagation(); openModal(slide); }}>
                                <span className="image-carousel-plus-icon">+</span>
                            </button>
                        </div>
                    ))}
                </div>
            </div>
            <div className="image-carousel-arrow-container">
                <button className="image-carousel-slider-arrow" onClick={handlePrev}>
                    &lt;
                </button>
                <button className="image-carousel-slider-arrow" onClick={handleNext}>
                    &gt;
                </button>
            </div>
            {modalOpen && (
                <div className="image-carousel-modal" onClick={handleModalClick}>
                    <div className="image-carousel-modal-content">
                        <button className="image-carousel-close-button" onClick={closeModal}>
                            &times;
                        </button>
                        <h2 className='card-heading'>{modalContent.modalTitle}</h2>
                        <div className="modal-content">
                            <p>{modalContent.modalText}</p>
                            <img src={modalContent.modalImage} alt={modalContent.title} className="modal-image" />
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default ImageCarousel;
