import React, { useState, useEffect } from 'react';
import './FloorPlanDemo.css';
import demoVideo from './Images/DemoVideo.mp4'; // Replace with your video file path

const FloorPlanDemo = () => {
    const [headingVisible, setHeadingVisible] = useState(false);
    const [contentVisible, setContentVisible] = useState(false);

    useEffect(() => {
        const handleScroll = () => {
            const section = document.querySelector('.floor-plan-demo');
            const sectionPosition = section.getBoundingClientRect().top;
            const screenPosition = window.innerHeight / 1.5;

            if (sectionPosition < screenPosition) {
                setHeadingVisible(true);
                setTimeout(() => {
                    setContentVisible(true);
                }, 500); // Delay to show the content after heading is visible
                window.removeEventListener('scroll', handleScroll);
            }
        };

        window.addEventListener('scroll', handleScroll);
        return () => window.removeEventListener('scroll', handleScroll);
    }, []);

    return (
        <div className="floor-plan-demo">
            <h2 className={`floor-plan-heading ${headingVisible ? 'appear' : ''}`}>As easy as 1,2,3...</h2>
            <div className={`floor-plan-content ${contentVisible ? 'visible' : ''}`}>
                <div className="text-content">
                    <p>Create your ideal home with ease. <span style={{ fontWeight: "bold" }}>Move walls and swap rooms</span> to match your vision.</p>
                </div>
                <div className="video-container">
                    <video autoPlay loop muted playsInline className="background-video">
                        <source src={demoVideo} type="video/mp4" />
                        Your browser does not support the video tag.
                    </video>
                </div>
            </div>
        </div>
    );
};

export default FloorPlanDemo;
