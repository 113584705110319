import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import CustomDropdown from './CustomDropdown';
import Modern from './Images/Modern.jpg';
import Minimal from './Images/Minimal.jpg';
import Traditional from './Images/Traditional.jpg';
import './formsecond.css';


const DreamHomeForm2 = () => {
  const [step, setStep] = useState(1);
  const [rooms, setRooms] = useState([]);
  const navigate = useNavigate();
  const location = useLocation();
  const { id } = location.state; // Extract id from location state
  const [floors, setFloors] = useState({ num_floors: 0 });
  const [area, setArea] = useState();
  const [direction, setDirection] = useState();
  const [lift, setLift] = useState();
  const [vastu, setVastu] = useState();
  const [residence_type, setResidenceType] = useState();
  const [numVehicles, setNumVehicles] = useState({
    two_wheeler: 0,
    four_wheeler: 0,
  });
  const [animating, setAnimating] = useState(false);

  const [formData, setFormData] = useState({
    name: '',
    mobile: '',
    num_two_wheeler: 0,
    num_four_wheeler: 0,
    staircase: '',
    ug_water_tank: false,
    landscape_front_general: false,
    landscape_back_general: false,
    might_need_room_stilt: false,
    gazebo_roof: false,
    seating_roof: false,
    landscaping_roof: false,
    solar_panel_roof: false,
    designPreference: '',
    floors: {}
  });

  useEffect(() => {
    axios.get(`https://www.veshmn.com/api/Dream-Home/${id}/`)
      .then(response => {
        setFloors(response.data);
        const width = response.data.width;
        const height = response.data.height;
        const totalArea = width * height / 9;
        setArea(totalArea);
        setLift(response.data.lift);
        setVastu(response.data.vastu);
        setDirection(response.data.direction);
        setResidenceType(response.data.residence_type);
        setFormData(prevState => ({
          ...prevState,
          name: response.data.name,
          mobile: response.data.mobile
        }));

        if (totalArea <= 100) {
          const defaultRooms = Array.from({ length: response.data.num_floors }, () => [{ id: 1, roomType: '' }]);
          setRooms(defaultRooms);
        } else if (totalArea <= 150 && totalArea > 100) {
          const defaultRooms = Array.from({ length: response.data.num_floors }, () => [{ id: 1, roomType: '' }, { id: 2, roomType: '' }]);
          setRooms(defaultRooms);
        } else if (totalArea <= 175 && totalArea > 150) {
          const defaultRooms = Array.from({ length: response.data.num_floors }, () => [{ id: 1, roomType: '' }, { id: 2, roomType: '' }]);
          setRooms(defaultRooms);
        } else if (totalArea > 175) {
          const defaultRooms = Array.from({ length: response.data.num_floors }, () => [{ id: 1, roomType: '' }, { id: 2, roomType: '' }, { id: 3, roomType: '' }]);
          setRooms(defaultRooms);
        }
      })
      .catch(error => {
        console.error('Error fetching floors data:', error);
      });
  }, [id]);

  const handleIncrement = (category) => {
    if (area <= 175 && numVehicles[category] < 4) {
      setNumVehicles(prevNumVehicles => ({
        ...prevNumVehicles,
        [category]: prevNumVehicles[category] + 1
      }));
    } else if (area > 175 && numVehicles[category] < 5) {
      setNumVehicles(prevNumVehicles => ({
        ...prevNumVehicles,
        [category]: prevNumVehicles[category] + 1
      }));
    } else {
      alert("You cannot add more vehicles as per your plot dimension.");
    }
  };

  const handleDecrement = (category) => {
    if (numVehicles[category] > 0) {
      setNumVehicles(prevNumVehicles => ({
        ...prevNumVehicles,
        [category]: prevNumVehicles[category] - 1
      }));
    }
  };

  const handleChange = (e, floorIndex, roomIndex) => {
    const { name, value, type, checked } = e.target;
    const inputValue = type === 'checkbox' ? checked : value;

    setFormData(prevFormData => {
      if (floorIndex !== undefined && roomIndex !== undefined) {
        const updatedFloors = { ...prevFormData.floors };
        const floorKey = `floor${floorIndex + 1}`;
        const roomKey = `room${roomIndex}`;

        if (!updatedFloors[floorKey]) {
          updatedFloors[floorKey] = {};
        }
        if (!updatedFloors[floorKey][roomKey]) {
          updatedFloors[floorKey][roomKey] = {};
        }
        updatedFloors[floorKey][roomKey][name] = inputValue;

        return { ...prevFormData, floors: updatedFloors };
      } else if (floorIndex !== undefined) {
        const updatedFloors = { ...prevFormData.floors };
        const floorKey = `floor${floorIndex + 1}`;
        if (!updatedFloors[floorKey]) {
          updatedFloors[floorKey] = {};
        }
        if (!updatedFloors[floorKey].floorData) {
          updatedFloors[floorKey].floorData = {};
        }
        updatedFloors[floorKey].floorData[name] = inputValue;

        return { ...prevFormData, floors: updatedFloors };
      } else {
        return {
          ...prevFormData,
          num_two_wheeler: numVehicles.two_wheeler,
          num_four_wheeler: numVehicles.four_wheeler,
          [name]: inputValue
        };
      }
    });
  };

  function getCookie(name) {
    const value = `; ${document.cookie}`;
    const parts = value.split(`; ${name}=`);
    if (parts.length === 2) return parts.pop().split(';').shift();
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log(formData);
    try {
      const csrftoken = getCookie('csrftoken');
      const config = {
        headers: {
          'X-CSRFToken': csrftoken
        }
      };
      const response = await axios.post('https://www.veshmn.com/api/Dream-Home-Form-1/', formData, config);
      console.log(response);

      let area_range;
      if (area * 9 <= 900) area_range = 1;
      if (area * 9 > 900 && area <= 1350) area_range = 2;
      if (area * 9 > 1350 && area <= 1575) area_range = 3;
      if (area * 9 > 1575 && area <= 1800) area_range = 4;
      if (area * 9 > 1800 && area <= 2250) area_range = 5;
      if (area * 9 > 2250 && area <= 2475) area_range = 6;
      if (area * 9 > 2475 && area <= 2700) area_range = 7;
      if (area * 9 > 2700) area_range = 8;

      const url = `https://www.veshmn.com/api/pixi-data/`;
      fetch(url)
        .then(response => response.json())
        .then(data => {
          const matchingObject = data.filter(item => item.area_range === area_range);
          let maxMatchingObject = matchingObject[0];
          let maxMatchCount = 0;
          matchingObject.forEach(item => {
            let matchCount = 0;
            if (item.direction === direction) matchCount++;
            if (item.lift === lift) matchCount++;
            if (item.vastu === vastu) matchCount++;
            if (item.residence_type === residence_type) matchCount++;
            if (matchCount > maxMatchCount) {
              maxMatchCount = matchCount;
              maxMatchingObject = item;
            }
          });
          if (maxMatchingObject) {
            navigate(`/model/${maxMatchingObject.id}/`, { state: { formData: formData } });
          } else {
            console.error('No matching data found');
            navigate(`/model/special-requirement/`, { state: { formData: formData } });
          }
        });
    } catch (error) {
      console.error('Error submitting data:', error);
    }
  };

  const addRoom = (floorIndex) => {
    const newId = rooms[floorIndex].length + 1;
    if (area <= 175 && newId < 4) {
      setRooms(prevRooms => {
        const updatedRooms = [...prevRooms];
        updatedRooms[floorIndex] = [...updatedRooms[floorIndex], { id: newId, roomType: '' }];
        return updatedRooms;
      });
    } else if (area > 175 && newId < 5) {
      setRooms(prevRooms => {
        const updatedRooms = [...prevRooms];
        updatedRooms[floorIndex] = [...updatedRooms[floorIndex], { id: newId, roomType: '' }];
        return updatedRooms;
      });
    } else {
      alert("You cannot add rooms as per your plot dimension.");
    }
  };

  const removeRoom = (floorIndex, id) => {
    setRooms(prevRooms => {
      const updatedRooms = [...prevRooms];
      updatedRooms[floorIndex] = updatedRooms[floorIndex].filter(room => room.id !== id);
      return updatedRooms;
    });
  };

  const handleNext = () => {
    if (step < floors.num_floors + 3) {
      setAnimating(true);
      setTimeout(() => {
        setAnimating(false);
        setStep(step + 1);
      }, 500);
    }
  };

  const handlePrev = () => {
    if (step > 1) {
      setAnimating(true);
      setTimeout(() => {
        setAnimating(false);
        setStep(step - 1);
      }, 500);
    }
  };

  const progressPercentage = Math.round((step / (2 + floors.num_floors + 1)) * 100);

  const roomOptions = [
    { value: 'Pradhan Haven (Main Master Bedroom)', label: 'Pradhan Haven (Main Master Bedroom)', shortLabel: 'Pradhan Haven (Main Master Bedroom)' },
    { value: 'Mukhya Retreat (Master Bedroom)', label: 'Mukhya Retreat (Master Bedroom)', shortLabel: 'Mukhya Retreat (Master Bedroom)' },
    { value: 'Manthan Haven (Kids Bedroom)', label: 'Manthan Haven (Kids Bedroom)', shortLabel: 'Manthan Haven (Kids Bedroom)' },
    { value: 'Ananda Retreat (Guest Bedroom)', label: 'Ananda Retreat (Guest Bedroom)', shortLabel: 'Ananda Retreat (Guest Bedroom)' },
    { value: 'Param Sanctuary (Grandparent Bedroom)', label: 'Param Sanctuary (Grandparent Bedroom)', shortLabel: 'Param Sanctuary (Grandparent Bedroom)' }
  ];

  return (
    <div className="container-wrapper">
      <div className="container">
        <div className="progress-bar">
          <div style={{ width: `${progressPercentage}%` }}>{progressPercentage}%</div>
        </div>
        <h1 className='form-heading'>{step === 1 ? 'General Section' : step <= floors.num_floors + 1 ? `Floor ${step - 1} Planning` : step === floors.num_floors + 2 ? 'Roof Planning' : 'Architectural Design'}</h1>
        <form onSubmit={handleSubmit}>
          <div className={`card-start ${animating ? 'card-exit' : ''}`}>
            {step === 1 && (
              <div>
                <div className="checkbox-row">
                  <div className="form-check">
                    <input className="form-check-input" type="checkbox" name="ug_water_tank" checked={formData.ug_water_tank} onChange={handleChange} />
                    <label className="form-check-label">UG Water tank</label>
                  </div>
                  <div className="form-check">
                    <input className="form-check-input" type="checkbox" name="landscape_front_general" checked={formData.landscape_front_general} onChange={handleChange} />
                    <label className="form-check-label">Landscaping (Front)</label>
                  </div>
                  <div className="form-check">
                    <input className="form-check-input" type="checkbox" name="landscape_back_general" checked={formData.landscape_back_general} onChange={handleChange} />
                    <label className="form-check-label">Landscaping (Back)</label>
                  </div>
                  <div className="form-check">
                    <input className="form-check-input" type="checkbox" name="might_need_room_stilt" checked={formData.might_need_room_stilt} onChange={handleChange} />
                    <label className="form-check-label">Might need a room at slit floor </label>
                  </div>
                </div>
                <hr></hr>
                <div className="row mt-2">
                  <div className="col-12 form-group">
                    <label>Parking</label>
                  </div>
                  <div className="row align-items-center">
                    <div className="input-group">
                      <div className="mt-2 form-group" style={{ fontSize: "14px", textAlign: "left", marginRight: "auto" }}>
                        <label>Number of two wheeler</label>
                      </div>
                      <button className="decrement-button" onClick={() => handleDecrement('two_wheeler')} type="button" style={{ zIndex: 0, backgroundColor: '#7f7f80' }}>-</button>
                      <h6 className="text-center mb-0">{numVehicles.two_wheeler}</h6>
                      <button className="increment-button" onClick={() => handleIncrement('two_wheeler')} type="button" style={{ zIndex: 0, backgroundColor: '#7f7f80' }}>+</button>
                    </div>
                  </div>
                  <div className="row align-items-center">
                    <div className="input-group">
                      <div className="mt-2 form-group" style={{ fontSize: "14px", textAlign: "left", marginRight: "auto" }}>
                        <label>Number of four wheeler</label>
                      </div>
                      <button className="decrement-button" onClick={() => handleDecrement('four_wheeler')} type="button" style={{ zIndex: 0, backgroundColor: '#7f7f80' }}>-</button>
                      <h6 className="text-center">{numVehicles.four_wheeler}</h6>
                      <button className="increment-button" onClick={() => handleIncrement('four_wheeler')} type="button" style={{ zIndex: 0, backgroundColor: '#7f7f80' }}>+</button>
                    </div>
                  </div>
                  <hr></hr>
                </div>
                <div className="form-group mt-3">
                  <CustomDropdown
                    label="Staircase"
                    name="staircase"
                    options={[
                      { value: 'External', label: 'External Staircase', shortLabel: 'External Staircase' },
                      { value: 'Internal', label: 'Internal Staircase', shortLabel: 'Internal Staircase' }
                    ]}
                    handleChange={handleChange}
                    value={formData.staircase}
                  />
                </div>
              </div>
            )}
            {step > 1 && step <= floors.num_floors + 1 && (
              <div>
                {rooms[step - 2].map(room => (
                  <div className="form-group" key={room.id}>
                    <CustomDropdown
                      label="Room Type"
                      name="roomType"
                      options={roomOptions}
                      handleChange={(e) => handleChange(e, step - 2, room.id)}
                      value={formData.floors[`floor${step - 1}`]?.[`room${room.id}`]?.roomType || ''}
                    />
                    <button type="button" onClick={() => removeRoom(step - 2, room.id)}>Remove Room</button>
                  </div>
                ))}
                <button type="button" onClick={() => addRoom(step - 2)}>Add Room</button>
                <div className="checkbox-row mt-3">
                  {['open_seating', 'shaded_seating', 'storage_room small', 'storage_room big', 'shoe_rack', 'powder_room', 'living_room', 'utility_area', 'balcony', 'pantry'].map((checkboxName) => (
                    <div className="form-check" key={checkboxName}>
                      <input
                        className="form-check-input "
                        type="checkbox"
                        name={checkboxName}
                        checked={formData.floors[`floor${step - 1}`]?.floorData?.[checkboxName] || false}
                        onChange={(e) => handleChange(e, step - 2)}
                      />
                      <label className="form-check-label">
                        {checkboxName.replace('_', ' ').replace(/\b\w/g, c => c.toUpperCase())}
                      </label>
                    </div>
                  ))}
                </div>
              </div>
            )}
            {step === floors.num_floors + 2 && (
              <div>
                {/* <h4>Roof Planning</h4> */}
                <div className="checkbox-row">
                  <div className="form-check">
                    <input className="form-check-input" type="checkbox" name="gazebo_roof" checked={formData.gazebo_roof} onChange={handleChange} />
                    <label className="form-check-label">Gazebo</label>
                  </div>
                  <div className="form-check">
                    <input className="form-check-input" type="checkbox" name="seating_roof" checked={formData.seating_roof} onChange={handleChange} />
                    <label className="form-check-label">Seating</label>
                  </div>
                  <div className="form-check">
                    <input className="form-check-input" type="checkbox" name="landscaping_roof" checked={formData.landscaping_roof} onChange={handleChange} />
                    <label className="form-check-label">Landscaping</label>
                  </div>
                  <div className="form-check">
                    <input className="form-check-input" type="checkbox" name="solar_panel_roof" checked={formData.solar_panel_roof} onChange={handleChange} />
                    <label className="form-check-label">Solar Panel</label>
                  </div>
                </div>
              </div>
            )}

            {step === floors.num_floors + 3 && (
              <div>
                <div className="row mt-3">
                  <h6>Your Design Preferences*</h6>
                  <div className="col-md m-1">
                    <div
                      className="row btn"
                      style={{
                        backgroundImage: `url(${Modern})`,
                        backgroundRepeat: 'no-repeat',
                        backgroundSize: 'cover',
                        backgroundPosition: 'center',
                        width: '100%',
                        height: '200px',
                        cursor: 'pointer' // Add pointer cursor to indicate clickability
                      }}
                      onClick={() => handleChange({ target: { name: 'designPreference', value: 'modern' } })}
                    ></div>
                    <div className="form-check">
                      <input
                        className="form-check-input"
                        type="radio"
                        name="designPreference"
                        value="modern"
                        onChange={handleChange}
                        checked={formData.designPreference === 'modern'}
                      />
                      <label className="form-check-label">Modern / Contemporary</label>
                    </div>
                  </div>
                  <div className="col-md m-1">
                    <div
                      className="row btn"
                      style={{
                        backgroundImage: `url(${Minimal})`,
                        backgroundRepeat: 'no-repeat',
                        backgroundSize: 'cover',
                        backgroundPosition: 'center',
                        width: '100%',
                        height: '200px',
                        cursor: 'pointer' // Add pointer cursor to indicate clickability
                      }}
                      onClick={() => handleChange({ target: { name: 'designPreference', value: 'minimal' } })}
                    ></div>
                    <div className="form-check">
                      <input
                        className="form-check-input"
                        type="radio"
                        name="designPreference"
                        value="minimal"
                        onChange={handleChange}
                        checked={formData.designPreference === 'minimal'}
                      />
                      <label className="form-check-label">Minimal</label>
                    </div>
                  </div>
                  <div className="col-md m-1">
                    <div
                      className="row btn"
                      style={{
                        backgroundImage: `url(${Traditional})`,
                        backgroundRepeat: 'no-repeat',
                        backgroundSize: 'cover',
                        backgroundPosition: 'center',
                        width: '100%',
                        height: '200px',
                        cursor: 'pointer' // Add pointer cursor to indicate clickability
                      }}
                      onClick={() => handleChange({ target: { name: 'designPreference', value: 'traditional' } })}
                    ></div>
                    <div className="form-check">
                      <input
                        className="form-check-input"
                        type="radio"
                        name="designPreference"
                        value="traditional"
                        onChange={handleChange}
                        checked={formData.designPreference === 'traditional'}
                      />
                      <label className="form-check-label">Traditional / Vernacular</label>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
          <div className="button-container">
            {step > 1 && <button type="button" onClick={handlePrev}>Back</button>}
            {step < floors.num_floors + 3 && <button type="button" className="button-next" onClick={handleNext}>Next</button>}
            {step === floors.num_floors + 3 && <button type="submit">Submit</button>}
          </div>
        </form>
      </div>
    </div>
  );
};

export default DreamHomeForm2;