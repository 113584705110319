import React from 'react';
import './footer.css'; // Import the CSS file
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebookF, faInstagram, faLinkedin } from '@fortawesome/free-brands-svg-icons';

const Footer = () => {
    return (
        <div className="footer-container">
            <div className="footer-main-text">
                <div className="footer-text-line">Feel</div>
                <div className="footer-text-line">Settled.</div>
            </div>
            <section className="footer-social-icons">
                <div>
                    <a href="https://in.linkedin.com/company/veshmn" className="me-4 text-reset" target="_blank" rel="noopener noreferrer">
                        <FontAwesomeIcon icon={faLinkedin} />
                    </a>
                    <a href="https://www.instagram.com/veshmn.official/" className="me-4 text-reset" target="_blank" rel="noopener noreferrer">
                        <FontAwesomeIcon icon={faInstagram} />
                    </a>
                    <a href="https://www.facebook.com/veshmn/" className="me-4 text-reset" target="_blank" rel="noopener noreferrer">
                        <FontAwesomeIcon icon={faFacebookF} />
                    </a>
                </div>
            </section>
            <ul className="footer-list">
                <li><a href="https://www.veshmn.com/requirement">Start Building</a></li>
                {/* <li><a href="https://in.linkedin.com/company/veshmn">Features</a></li> */}
                {/* <li><a href="https://in.linkedin.com/company/veshmn">Pricing</a></li> */}
                {/* <li><a href="https://in.linkedin.com/company/veshmn">FAQs</a></li> */}
                {/* <li><a href="https://in.linkedin.com/company/veshmn">About</a></li> */}
                <li><a href="tel:+918368265309" className="nav-link">Call Us: <span style={{ fontSize: '.7rem' }}>+91-8368265309</span></a></li>

            </ul>
            <div className="footer-copyright">
                © 2024
            </div>
        </div>
    );
};

export default Footer;