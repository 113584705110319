import React, { useState, useEffect } from 'react';
import './HomePage.css';
import { useNavigate } from 'react-router-dom';
// import heroImage from './Images/Heroimage.jpg';
import axios from 'axios';


const sentences = [
    "Veshmn - Easiest way to build homes",
    "Feel home, feel love",
    "Feel Settled."
];

const HomePage = () => {
    const navigate = useNavigate();
    const [currentSentence, setCurrentSentence] = useState(0);
    const [displayText, setDisplayText] = useState('');
    const [isDeleting, setIsDeleting] = useState(false);
    const [loopNum, setLoopNum] = useState(0);
    const [typingSpeed, setTypingSpeed] = useState(150);
    const [isButtonSticky, setIsButtonSticky] = useState(false);
    const [heroImageUrl, setHeroImageUrl] = useState('');


    useEffect(() => {
        // Fetch the hero image URL from the backend
        axios.get('https://www.veshmn.com/api/heroimage/')
            .then(response => {
                const heroImageData = response.data;
                if (heroImageData.length > 0) {
                    setHeroImageUrl(heroImageData[0].HeroImage);
                }
            })
            .catch(error => {
                console.error('Error fetching the hero image URL:', error);
            });

        const handleType = () => {
            const fullText = sentences[loopNum % sentences.length];
            const updatedText = isDeleting
                ? fullText.substring(0, displayText.length - 1)
                : fullText.substring(0, displayText.length + 1);

            setDisplayText(updatedText);

            if (!isDeleting && updatedText === fullText) {
                setTimeout(() => setIsDeleting(true), 1000);
            } else if (isDeleting && updatedText === '') {
                setIsDeleting(false);
                setLoopNum(loopNum + 1);
                setCurrentSentence((currentSentence + 1) % sentences.length);
            }

            setTypingSpeed(isDeleting ? 20 : 100);
        };

        const timer = setTimeout(handleType, typingSpeed);

        return () => clearTimeout(timer);
    }, [displayText, isDeleting, loopNum, typingSpeed, currentSentence]);

    useEffect(() => {
        const handleScroll = () => {
            const homeSection = document.querySelector('.home-section');
            const rect = homeSection.getBoundingClientRect();
            const isVisible = rect.top < 0 || rect.bottom > window.innerHeight;
            setIsButtonSticky(isVisible);
        };

        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    const handleMouseMove = (e) => {
        const homeSection = document.querySelector('.home-section');
        const rect = homeSection.getBoundingClientRect();
        const x = e.clientX - rect.left;
        const y = e.clientY - rect.top;

        const revealCircle = document.querySelector('.reveal-circle');
        revealCircle.style.clipPath = `circle(100px at ${x}px ${y}px)`;
    };

    const handleTouchMove = (e) => {
        const homeSection = document.querySelector('.home-section');
        const rect = homeSection.getBoundingClientRect();
        const touch = e.touches[0];
        const x = touch.clientX - rect.left;
        const y = touch.clientY - rect.top;

        const revealCircle = document.querySelector('.reveal-circle');
        revealCircle.style.clipPath = `circle(100px at ${x}px ${y}px)`;
    };


    return (
        <div
            className="home-section"
            id="Home"
            onMouseMove={handleMouseMove}
            onTouchMove={handleTouchMove}
            style={{ backgroundImage: `url(${heroImageUrl})` }}
        >
            <div className="background-blur">
                <div className="reveal-circle"></div>
            </div>
            <div className="home-text">
                <h1 className="typewriter-heroimage">{displayText}<span className="cursor-heroimage">|</span></h1>
                <button
                    className={`cta-button ${isButtonSticky ? 'sticky' : ''}`}
                    onClick={() => navigate('/requirement')}
                >
                    Start building now
                </button>
            </div>
        </div>
    );
};

export default HomePage;