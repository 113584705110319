import React, { useState, useEffect } from 'react';
import './CustomResidents.css';

const CustomResidents = ({ handleChange, formData }) => {
  const [residents, setResidents] = useState({
    num_adults: formData.num_adults,
    num_old_adults: formData.num_old_adults,
    num_middle_adults: formData.num_middle_adults,
    num_teenagers: formData.num_teenagers,
    num_kids: formData.num_kids,
  });

  useEffect(() => {
    setResidents({
      num_adults: formData.num_adults,
      num_old_adults: formData.num_old_adults,
      num_middle_adults: formData.num_middle_adults,
      num_teenagers: formData.num_teenagers,
      num_kids: formData.num_kids,
    });
  }, [formData]);

  useEffect(() => {
    Object.keys(residents).forEach((key) => {
      if (formData[key] !== residents[key]) {
        handleChange({ target: { name: key, value: residents[key] } });
      }
    });
  }, [residents, handleChange, formData]);

  const incrementResident = (event, key) => {
    event.preventDefault();
    setResidents((prevResidents) => ({
      ...prevResidents,
      [key]: prevResidents[key] + 1,
    }));
  };

  const decrementResident = (event, key) => {
    event.preventDefault();
    setResidents((prevResidents) => ({
      ...prevResidents,
      [key]: Math.max(0, prevResidents[key] - 1),
    }));
  };

  return (
    <div className="residents-container">
      <div className="resident-row">
        <label className="resident-name">Number of Adults (Age 60 and above)</label>
        <button
          className="decrement-button"
          onClick={(e) => decrementResident(e, 'num_adults')}
        >
          -
        </button>
        <span className="count">{residents.num_adults}</span>
        <button
          className="increment-button"
          onClick={(e) => incrementResident(e, 'num_adults')}
        >
          +
        </button>
      </div>
      <div className="resident-row">
        <label className="resident-name">Number of Old Adults (Ages 40-59yrs)</label>
        <button
          className="decrement-button"
          onClick={(e) => decrementResident(e, 'num_old_adults')}
        >
          -
        </button>
        <span className="count">{residents.num_old_adults}</span>
        <button
          className="increment-button"
          onClick={(e) => incrementResident(e, 'num_old_adults')}
        >
          +
        </button>
      </div>
      <div className="resident-row">
        <label className="resident-name">Number of Middle Adults (Ages 20-39yrs)</label>
        <button
          className="decrement-button"
          onClick={(e) => decrementResident(e, 'num_middle_adults')}
        >
          -
        </button>
        <span className="count">{residents.num_middle_adults}</span>
        <button
          className="increment-button"
          onClick={(e) => incrementResident(e, 'num_middle_adults')}
        >
          +
        </button>
      </div>
      <div className="resident-row">
        <label className="resident-name">Number of Teenagers (Ages 13-19yrs)</label>
        <button
          className="decrement-button"
          onClick={(e) => decrementResident(e, 'num_teenagers')}
        >
          -
        </button>
        <span className="count">{residents.num_teenagers}</span>
        <button
          className="increment-button"
          onClick={(e) => incrementResident(e, 'num_teenagers')}
        >
          +
        </button>
      </div>
      <div className="resident-row">
        <label className="resident-name">Number of Kids (Ages 0-12yrs)</label>
        <button
          className="decrement-button"
          onClick={(e) => decrementResident(e, 'num_kids')}
        >
          -
        </button>
        <span className="count">{residents.num_kids}</span>
        <button
          className="increment-button"
          onClick={(e) => incrementResident(e, 'num_kids')}
        >
          +
        </button>
      </div>
    </div>
  );
};

export default CustomResidents;
