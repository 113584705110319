import React, { useState } from 'react';
import './CustomDropdown.css';

const CustomDropdown = ({ label, name, options, handleChange, value, floorIndex, roomIndex }) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleDropdown = () => setIsOpen(!isOpen);
  const selectOption = (option) => {
    handleChange({
      target: {
        name,
        value: option.value,
        floorIndex,
        roomIndex,
      },
    });
    setIsOpen(false);
  };

  const selectedOption = options.find(option => option.value === value);

  return (
    <div className="custom-dropdown">
      <label>{label}</label>
      <div className={`dropdown-header ${isOpen ? 'open' : ''}`} onClick={toggleDropdown}>
        <span>{selectedOption ? selectedOption.shortLabel : `Select ${label}`}</span>
        <span className="arrow">&#9662;</span>
      </div>
      {isOpen && (
        <ul className="dropdown-list">
          {options.map((option) => (
            <li key={option.value} className="dropdown-list-item" onClick={() => selectOption(option)}>
              {option.label}
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

export default CustomDropdown;