import React, { useEffect, useRef, useState } from 'react';
import './MoreAboutYourHome.css';
import mainImage from './Image/logo2.png'; // Replace with your main image path
import subImage1 from './Image/Air.png'; // Replace with your sub images path
import subImage2 from './Image/Sun.png';
import subImage3 from './Image/vastu.png';
import subImage4 from './Image/Space.png';

const MoreAboutYourHome = () => {
  const [isRotated, setIsRotated] = useState(false);
  const [showQuadrants, setShowQuadrants] = useState(false);
  const [hasRotated, setHasRotated] = useState(false);
  const sectionRef = useRef(null);

  
  useEffect(() => {
    const handleScroll = () => {
        const sectionTop = sectionRef.current.getBoundingClientRect().top;
        const windowHeight = window.innerHeight;

        if (sectionTop < windowHeight && !hasRotated) {
            setIsRotated(true);
            setHasRotated(true); // Set the state to true to prevent further rotations
            setTimeout(() => setShowQuadrants(true), 1500); // Adjust delay to sync with rotation duration
        }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
        window.removeEventListener('scroll', handleScroll);
    };
}, [hasRotated]);



  return (
    <div className="more-about-your-home-section" ref={sectionRef}>
      <h2 className="section-heading">More about your home</h2>
      <div className={`main-image-container ${isRotated ? 'rotate' : ''}`}>
        <img src={mainImage} alt="Main" className="main-image-container" />
      </div>
      <div className={`quadrant quadrant1 ${showQuadrants ? 'appear' : ''}`}>
        <img src={subImage1} alt="Sub 1" className="quadrant1" />
        <p className="quadrant1-text">Designed for natural ventilation, the space stays cool throughout the day, creating a refreshing atmosphere.</p>
      </div>
      <div className={`quadrant quadrant2 ${showQuadrants ? 'appear' : ''}`}>
        <img src={subImage2} alt="Sub 2" className="quadrant2" />
        <p className="quadrant2-text">The design naturally makes all the spaces lit and offers a bright and cheerful home.</p>
      </div>
      <div className={`quadrant quadrant3 ${showQuadrants ? 'appear' : ''}`}>
        <img src={subImage3} alt="Sub 3" className="quadrant3" />
        <p className="quadrant3-text">Aligned with Vastu principles, the space promotes peace and positive energy, making the home harmonious.</p>
      </div>
      <div className={`quadrant quadrant4 ${showQuadrants ? 'appear' : ''}`}>
        <img src={subImage4} alt="Sub 4" className="quadrant4" />
        <p className="quadrant4-text">The planning maximises the space and  provides an open and airy feel throughout.</p>
      </div>
    </div>
  );
};

export default MoreAboutYourHome;
