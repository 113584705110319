import React, { useState, useEffect } from 'react';
import Property from './components/PropertyDetails.js';
import './App.css';
import BodyComponent from "./components/body.js";
import StoryOfCustomer from './components/Story.js';
import Footer from './components/footer.js';
import Planbutton from "./components/Planbutton.js";
import { BrowserRouter as Router, Routes, Route, useLocation } from 'react-router-dom';
import PixiComponent from './components/Pixi-Components';
import UndefinedPage from './components/UndefinedPage.js';
import TagManager from 'react-gtm-module';
import Temp from './components/temp.js';
import ImageCarousel from './components/ImageCarousel.js';
import ExpertVisit from './components/ExpertVisit.js';
import NextStepSection from './components/NextStepSection.js';
import MoreAboutYourHome from './components/MoreAboutYourHome.js';
import WhatsInside from './components/WhatsInside.js';
import GallerySection from './components/Gallery.js';
import DreamHomeForm2 from './components/NewFeature/formsecond.js';
import DreamHomeForm from './components/NewFeature/formfirst.js';
import HomePage from './components/NewFeature/HomePage.js';
import HowItWorks from './components/NewFeature/HowItWorks.js';
import Header from './components/NewFeature/Header.js';
import WhatsInsideKit from './components/NewFeature/WhatsInsideKit.js';
import WhoeverYouAre from './components/NewFeature/WhoeverYouAre.js';
import PricingSection from './components/NewFeature/PricingSection.js';
import GalleryHome from './components/NewFeature/GallerySection.js';
import FloorPlanDemo from './components/NewFeature/FloorPlanDemo.js';
import Testimonials from './components/NewFeature/Testimonials.js';
import RenderVideo from './components/NewFeature/Render.js';
import WhyUs from './components/NewFeature/WhyUs.js';
import BlogCarousel from './components/NewFeature/BlogCarousel.js';
import BlogDetail from './components/NewFeature/BlogDetail';
import HeaderHome from './components/NewFeature/HeaderHome.js';
import Thankyou from './components/Thankyou.js';

const MainLayout = () => (
  <section>
    <HomePage />
    <HowItWorks />
    <RenderVideo />
    <FloorPlanDemo />
    <WhatsInsideKit />
    <WhoeverYouAre />
    <PricingSection />
    <Testimonials />
    <GalleryHome />
    <WhyUs />
    <BlogCarousel />
  </section>
);

function ThreeDPlanModel() { 
  const [isThreeD, setIsThreeD] = useState(true);
  const ModelComponent = isThreeD ? Temp : PixiComponent;
  return (
    <div className="container-fluid mt-custom">
      <div className="row">
        <div className="col">
          <Planbutton setIsThreeD={setIsThreeD} />
        </div>
      </div>
      <div className="row mt-4">
        <div className="col-xl-9 col-lg-9 col-md-12 col-sm-12 mb-4">
          <ModelComponent />
        </div>
        <div className="col-xl-3 col-lg-3 col-md-12 col-sm-12">
          <Property />
        </div>
      </div>
      <StoryOfCustomer />
      <BodyComponent />
      <ImageCarousel />
      <ExpertVisit />
      <MoreAboutYourHome />
      <NextStepSection />
      <WhatsInside />
      <PricingSection />
      <GallerySection />
      <BlogCarousel />
    </div>
  );
}

function ContactDetails() {
  return (
    <div className='mt-custom'>
      <DreamHomeForm />
    </div>
  );
}

function ContactDetails2() {
  return (
    <div className="mt-custom">
      <DreamHomeForm2 />
    </div>
  );
}

function BlogDetails() {
  return (
    <div className="mt-custom">
      <BlogDetail />
    </div>
  );
}

function UndefinedPageFunction() {
  return (
    <div className="mt-custom">
      <UndefinedPage />
    </div>
  );
}

function Thankyoufunction(){
  return (
    <div className="mt-custom">
      <Thankyou />
    </div>
  )
}

function ScrollToTop() {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
    TagManager.initialize({ gtmId: 'GTM-W5V9H5HR' });
  }, [pathname]);
  return null;
}

const ConditionalHeaderLayout = ({ children }) => {
  const location = useLocation();
  const isMainLayout = location.pathname === '/';

  return (
    <>
      {isMainLayout ? <HeaderHome /> : <Header />}
      {children}
      <Footer />
    </>
  );
};

function App() {
  return (
    <Router>
      <div className="App">
        <ScrollToTop />
        <ConditionalHeaderLayout>
          <Routes>
            <Route path="/" element={<MainLayout />} />
            <Route path="/requirement" element={<ContactDetails />} />
            <Route path="/custom-requirement" element={<ContactDetails2 />} />
            <Route path="/model/:planId" element={<ThreeDPlanModel />} />
            <Route path="/model/special-requirement" element={<UndefinedPageFunction />} />
            <Route path="/thankyou" element ={<Thankyoufunction/>}/>
            <Route path="/:blog/:id" element={<BlogDetails />} />
          </Routes>
        </ConditionalHeaderLayout>
      </div>
    </Router>
  );
}

export default App;