import React, { useEffect, useState } from 'react';
import * as PIXI from 'pixi.js';
import './Pixi-Components.css';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import alert_image from './Image/alert_image.png';
// import Tutorial_Image from './Image/Tutorial_Image.png';
import { useLocation } from 'react-router-dom';
import DemoVideo from './Image/DemoVideo.mp4';
import loaderImage from './Image/logo2.png'

const PixiComponent = () => {
  const { planId } = useParams();
  const [Width, setWidth] = useState(null);
  const [Height, setHeight] = useState(null);
  const location = useLocation();
  const [data, setData] = useState();
  const [loading, setLoading] = useState(true);

  // const [minX, setMinX] = useState(Infinity);
  // const [minY, setMinY] = useState(Infinity);
  // const [maxX, setMaxX] = useState(-Infinity);
  // const [maxY, setMaxY] = useState(-Infinity);

  useEffect(() => {
    const Url = `https://www.veshmn.com/api/plan-size/${planId}/`;
    axios.get(Url)
      .then(response => {
        const data1 = response.data;
        setWidth(data1.width);
        setHeight(data1.height);
      })
      .catch(error => {
        // Handle any errors that occur during the request
        console.error('Error fetching JSON data:', error);
      });
    let minX, minY, maxX, maxY;
    // console.log(Width);
    const dpr = window.devicePixelRatio || 1;
    // const adjustedWidth = Width * dpr;
    // const adjustedHeight = Height * dpr;

    const app = new PIXI.Application({
      width: Width,
      height: Height,
      backgroundColor: 0xffffff,
      resolution: dpr,
      autoDensity: true,
      // backgroundColor:  '#f8f9fa'
    });
    // const coordinatesText = new PIXI.Text('Coordinates: ', { fill: 0x000000 });
    //   coordinatesText.position.set(10, 10);
    //   app.stage.addChild(coordinatesText);

    //   app.view.addEventListener('mousemove', (event) => {
    //     const { offsetX, offsetY } = event;
    //     coordinatesText.text = `Coordinates: (${offsetX.toFixed(2)}, ${offsetY.toFixed(2)})`;
    //   });
    const formDataFromState = location.state && location.state.formData;
    console.log(formDataFromState);
    setData(formDataFromState);
    console.log(data);
    const pixiContainer = document.getElementById('pixi-container');

    const appWidth = window.innerWidth;
    const lines = [];
    const MovableRect = [];
    const MovableLine = [];
    const MovableArc = [];
    const lineConnections = new Map();
    // let box1 ;
    let id = planId;
    let boxes = {};
    console.log(planId);
    // Drag variables
    let scaleFactor = 1;

    if (appWidth <= 600) {
      scaleFactor = 1;
      // console.log(scaleFactor);
    }

    let json_data;
    // console.log(planId)
    const apiUrl = `https://www.veshmn.com/api/pixi-data/${planId}/`;
    // Make the GET request to your backend
    axios.get(apiUrl)
      .then(response => {
        // Handle a successful response from the backend
        const Data = response.data;
        json_data = Data.json_data;
        generateLines();
        showInstructionVideo("Some Walls are moveable, Click and drag to adjust your room size. You can drag one space over other to swap it based on your needs");
        setLoading(false); // Set loading to false on error
      })
      .catch(error => {
        // Handle any errors that occur during the request
        console.error('Error fetching JSON data:', error);
      });
    // jsonInput = document.getElementById('jsonInput').value;
    // console.log(jsonInput);


    function showInstructionVideo(errorMessage) {
      const modalContainer = new PIXI.Container();
      const modalBackground = new PIXI.Graphics();
      modalBackground.beginFill(0x000000, 0.75);
      modalBackground.drawRect(0, 0, app.screen.width, app.screen.height);
      modalBackground.endFill();
      modalBackground.interactive = true; // Make it clickable
    
      // Close modal when clicking on the background
      modalBackground.on('pointerdown', () => {
        modalContainer.destroy(); // Remove the modal container
      });
    
      // Create a video texture and sprite
      const videoTexture = PIXI.Texture.from(DemoVideo);
      const videoSprite = new PIXI.Sprite(videoTexture);
      videoSprite.anchor.set(.6);
      videoSprite.x = app.screen.width / 2;
      videoSprite.y = app.screen.height / 3; // Position the video slightly higher
      videoSprite.width = app.screen.width / 2;
      videoSprite.height = app.screen.height / 2;
    
      // Get the video element and set it to loop, autoplay, and hide controls
      const videoElement = videoTexture.baseTexture.resource.source;
      videoElement.loop = true;
      videoElement.autoplay = true;
      videoElement.controls = false;
      videoElement.muted = true; // Optional: mute the video if desired
    
      // Add interaction to show plan when the video is clicked
      videoSprite.interactive = true;
      videoSprite.on('pointerdown', () => {
        // Show the plan (implementation depends on how the plan should be shown)
      });
    
      const textStyle = new PIXI.TextStyle({
        fill: '0x000000', // Change to a valid color code
        fontSize: 18,
        wordWrap: true,
        fontFamily: 'roboto',
        wordWrapWidth: 300,
        align: 'center',
      });
    
      console.log(errorMessage);
      const message = new PIXI.Text(errorMessage, textStyle);
      message.anchor.set(0.5);
      message.x = app.screen.width / 2;
      message.y = videoSprite.y + (videoSprite.height / 2) + 60; // Position the text below the video
    
      // Add a background segment with rounded corners
      const backgroundSegment = new PIXI.Graphics();
      backgroundSegment.beginFill(0xffffff);
      backgroundSegment.drawRoundedRect(message.x - message.width / 2 - 10, message.y - message.height / 2 - 10, message.width + 20, message.height + 20, 10); // Adjust the last parameter for corner radius
      backgroundSegment.endFill();
    
      modalContainer.addChild(modalBackground, videoSprite, backgroundSegment, message);
      app.stage.addChild(modalContainer);
    }

    // function showInstruction(errorMessage) {
    //   const modalContainer = new PIXI.Container();
    //   const modalBackground = new PIXI.Graphics();
    //   modalBackground.beginFill(0x000000, 0.75);
    //   modalBackground.drawRect(0, 0, app.screen.width, app.screen.height);
    //   modalBackground.endFill();
    //   modalBackground.interactive = true; // Make it clickable

    //   // Close modal when clicking on the background
    //   modalBackground.on('pointerdown', () => {
    //     modalContainer.destroy(); // Remove the modal container
    //   });

    //   const image1 = PIXI.Sprite.from(Tutorial_Image);
    //   image1.anchor.set(0.5);
    //   image1.x = app.screen.width * 1 / 2;
    //   image1.y = app.screen.height * 1 / 6;

    //   const image = PIXI.Sprite.from(alert_image);
    //   image.anchor.set(0.5);
    //   image.x = app.screen.width * 3 / 4;
    //   image.y = app.screen.height * 3 / 4;

    //   const textStyle = new PIXI.TextStyle({
    //     fill: '000000',
    //     fontSize: 18,
    //     wordWrap: true,
    //     fontFamily: 'roboto',
    //     wordWrapWidth: 200,
    //     align: 'center',
    //   });

    //   const message = new PIXI.Text(errorMessage, textStyle);
    //   message.anchor.set(0.5);
    //   message.x = app.screen.width / 2;
    //   message.y = app.screen.height / 2 - 42;

    //   // Add a background segment with rounded corners
    //   const backgroundSegment = new PIXI.Graphics();
    //   backgroundSegment.beginFill(0xffffff);
    //   backgroundSegment.drawRoundedRect(message.x - message.width / 2 - 10, message.y - message.height / 2 - 10, message.width + 20, message.height + 20, 10); // Adjust the last parameter for corner radius
    //   backgroundSegment.endFill();
    //   modalContainer.addChild(modalBackground, image, backgroundSegment, message, image1);
    //   app.stage.addChild(modalContainer);
    // }


    function generateLines() {
      try {
        const jsonData = JSON.parse(json_data);
        let LocalminX = Infinity, LocalminY = Infinity, LocalmaxX = -Infinity, LocalmaxY = -Infinity;
        // app.stage.removeChildren();
        // console.log(json_data);
        lines.length = 0;
        lineConnections.clear();
        for (const key in jsonData) {
          if (jsonData.hasOwnProperty(key)) {
            const lineData = jsonData[key];
            const lineContainer = new PIXI.Container();
            lineContainer.interactive = true;
            lineContainer.buttonMode = true;
            lineContainer.data = { originalData: lineData };
            // console.log('x1:', lineData.x1, 'x2:', lineData.x2, 'y1:', lineData.y1, 'y2:', lineData.y2);
            if (key.startsWith("line")) {
              const x1 = Number(lineData.x1);
              const x2 = Number(lineData.x2);
              const y1 = Number(lineData.y1);
              const y2 = Number(lineData.y2);
              LocalminX = Math.min(LocalminX, x1, x2);
              LocalminY = Math.min(LocalminY, y1, y2);
              LocalmaxX = Math.max(LocalmaxX, x1, x2);
              LocalmaxY = Math.max(LocalmaxY, y1, y2);
            }
            lineContainer
              .on('pointerdown', onDragStart)
              .on('pointerup', onDragEnd)
              .on('pointerupoutside', onDragEnd)
              .on('pointermove', onDragMove);

            // console.log(lineData.Furn);
            if (lineData.MOVEMENT === "TRUE") {
              const graphics = new PIXI.Graphics();
              const x1 = lineData.x1;
              const y1 = lineData.y1;
              const x2 = lineData.x2;
              const y2 = lineData.y2;
              lineContainer.data.Name = key;
              // Draw the line
              graphics.lineStyle(4, 0x040505);
              graphics.moveTo(x1, y1);
              graphics.lineTo(x2, y2);
              // Calculate the hit area rectangle that encompasses the entire line
              const lineWidth = 20; // Adjust this based on your line's stroke width
              const hitAreaX = Math.min(x1, x2) - lineWidth;
              const hitAreaY = Math.min(y1, y2) - lineWidth;
              const hitAreaWidth = Math.abs(x2 - x1) + 2 * lineWidth;
              const hitAreaHeight = Math.abs(y2 - y1) + 2 * lineWidth;
              const hitAreaShape = new PIXI.Rectangle(hitAreaX, hitAreaY, hitAreaWidth, hitAreaHeight);
              graphics.hitArea = hitAreaShape;
              graphics.interactive = true;
              graphics.buttonMode = true;
              graphics.Name = key;
              graphics.Token = lineData.Token;
              // console.log(graphics)
              lineContainer.addChild(graphics);
              MovableLine.push(graphics);
            }
            else if (lineData.Furn === "TRUE") {
              const graphics = new PIXI.Graphics();
              graphics.lineStyle(1, 0x040505);
              graphics.moveTo(lineData.x1, lineData.y1);
              graphics.lineTo(lineData.x2, lineData.y2);
              lineContainer.addChild(graphics);
            }
            else if (lineData.walltype === "Interior") {
              const graphics = new PIXI.Graphics();
              graphics.lineStyle(4, 0x040505);
              graphics.moveTo(lineData.x1, lineData.y1);
              graphics.lineTo(lineData.x2, lineData.y2);
              lineContainer.addChild(graphics);
            }
            else if (lineData.walltype === "Exterior") {
              const graphics = new PIXI.Graphics();
              graphics.lineStyle(6, 0x040505);
              graphics.moveTo(lineData.x1, lineData.y1);
              graphics.lineTo(lineData.x2, lineData.y2);
              lineContainer.addChild(graphics);
            }
            else if (lineData.walltype === "Staircase") {
              const graphics = new PIXI.Graphics();
              graphics.lineStyle(2, 0x040505);
              graphics.moveTo(lineData.x1, lineData.y1);
              graphics.lineTo(lineData.x2, lineData.y2);
              lineContainer.addChild(graphics);
            }
            else if (lineData.walltype === "Tree") {
              const graphics = new PIXI.Graphics();
              graphics.lineStyle(3, 0x416d19);
              graphics.moveTo(lineData.x1, lineData.y1);
              graphics.lineTo(lineData.x2, lineData.y2);
              lineContainer.addChild(graphics);
            }
            else {
              const graphics = new PIXI.Graphics();
              graphics.lineStyle(1, 0x040505);
              graphics.moveTo(lineData.x1, lineData.y1);
              graphics.lineTo(lineData.x2, lineData.y2);
              lineContainer.addChild(graphics);
            }
            app.stage.addChild(lineContainer);
            // console.log(lineContainer.getChildAt(0));
            lines.push(lineContainer);
            // if (key === "MovableLineData") {
            //   DrawMovableLine(jsonData[key])
            // }
          }
        }

        establishLineConnections();
        // let i=0;
        for (const k in jsonData) {
          const areadata = jsonData[k];
          // console.log(k);
          if (k.startsWith("arc")) {
            drawArc(areadata, k);
          }
          if (k.startsWith("circle")) {
            drawCircle(areadata);
          }

          // Adding here Name of the Areas 

          if (k.startsWith("label")) {
            const name = jsonData[k].name;
            console.log(name);
            const textStyle = new PIXI.TextStyle({
              fontFamily: 'Roboto',
              fontSize: 12,
              fill: '#040505', // Color of the text
              align: 'left'
            });
            const text = new PIXI.Text(name, textStyle);
            text.anchor.set(0, 0); // Center the text's anchor point
            text.position.set(jsonData[k].x, jsonData[k].y); // Center the text in the box
            app.stage.addChild(text);
          }
          // let box="box";
          if (areadata.M) {
            // i= i+1;
            const areadata = jsonData[k];
            // let A, B, C, D;
            // A = areadata.M;
            // B = areadata.N;
            // C = areadata.Q;
            // D = areadata.R;
            const vertexKeys = ['M', 'N', 'O', 'P'];
            const minX = Math.min(...vertexKeys.map(key => areadata[key].x));
            const minY = Math.min(...vertexKeys.map(key => areadata[key].y));
            const maxX = Math.max(...vertexKeys.map(key => areadata[key].x));
            const maxY = Math.max(...vertexKeys.map(key => areadata[key].y));

            if (k.startsWith("rectangleposition")) {
              drawRectangle(areadata, k);
            }
            let areaswap = areadata.swappingarea;
            let errormessage = areadata.errormessage;
            // console.log(errormessage);
            // const width = Math.abs(B.x - A.x);
            // const height = Math.abs(C.y - A.y);
            let name = areadata.name;
            let tag = areadata.tag;
            console.log(tag);
            // console.log(name);
            let Furn = areadata.FURN;
            // console.log(doorName, doorPosition, doorDistance);
            boxes[name] = createBox(minX, minY, maxX - minX, maxY - minY, areaswap, errormessage, name, Furn, tag);
            console.log(boxes[name]);
            app.stage.addChild(boxes[name]);
          }
        }
        minX = LocalminX;
        minY = LocalminY;
        maxX = LocalmaxX;
        maxY = LocalmaxY;
      } catch (error) {
        console.error('Error parsing JSON data: ' + error.message);
      }
    }
    function drawRectangle(rectangledata, key) {
      // Create a Graphics object
      const graphics = new PIXI.Graphics();
      // Sort vertices based on x-coordinate and y-coordinate
      const sortedVertices = Object.keys(rectangledata).sort((a, b) => {
        return rectangledata[a].x - rectangledata[b].x || rectangledata[a].y - rectangledata[b].y;
      });

      // Set the line style for the rectangle's boundary
      graphics.lineStyle(1, '#355e3b'); // Line style: 1 pixels thick, Black color

      // Draw the rectangle using the sorted vertices
      for (const vertex of sortedVertices) {
        const { x, y } = rectangledata[vertex];
        if (vertex === sortedVertices[0]) {
          graphics.moveTo(x, y);
        } else {
          graphics.lineTo(x, y);
        }
      }
      graphics.interactive = true;
      graphics.Name = key;
      graphics.Token = rectangledata.Token;
      // Close the rectangle by drawing a line to the first vertex
      graphics.lineTo(rectangledata[sortedVertices[0]].x, rectangledata[sortedVertices[0]].y);
      // Add the Graphics object to the stage
      app.stage.addChild(graphics);
      MovableRect.push(graphics);
    }

    function drawArc(arcData, key) {
      const graphics = new PIXI.Graphics();
      graphics.lineStyle(1, '0x040505'); // Line style: 2 pixels thick, Black color
      // Define the center coordinates, radius, start angle, and end angle of the arc
      const centerX = arcData.cx;
      const centerY = arcData.cy;
      const radius = arcData.radius;
      const startAngle = arcData.startAngle; // Start angle in radians (0 means starting from the right)
      const endAngle = arcData.endAngle// End angle in radians (Math.PI/2 means drawing a quarter circle)
      // Draw the arc
      graphics.arc(centerX, centerY, radius, startAngle, endAngle);
      // graphics.lineTo(centerX, centerY);
      // Add the Graphics object to the stage
      graphics.Name = key;
      // console.log(arcData);
      graphics.Token = arcData.Token;
      app.stage.addChild(graphics);
      MovableArc.push(graphics);
    }

    function drawCircle(circleData) {
      const graphics = new PIXI.Graphics();
      // Set the fill color and line style for the circle
      graphics.beginFill(0xFF0000); // Fill color: Red
      graphics.lineStyle(1, 0x040505); // Line style: 1 pixels thick, Black color

      // Define the center coordinates and radius of the circle
      const centerX = circleData.cx;
      const centerY = circleData.cy;
      const radius = circleData.radius;

      // Draw the circle
      graphics.drawCircle(centerX, centerY, radius);

      // End the fill and line styles
      graphics.endFill();

      // Add the Graphics object to the stage
      app.stage.addChild(graphics);
    }
    if (pixiContainer) {
      pixiContainer.appendChild(app.view);
      app.stage.scale.set(scaleFactor);
      // Ensure touch events allow scrolling
      app.view.style.touchAction = 'manipulation'; // Ensure touch actions are allowed
      console.log(minX, minY, maxX, maxY);

      const isInsideFloorPlan = (x, y) => {
        return (
          x >= minX &&
          x <= maxX &&
          y >= minY &&
          y <= maxY
        );
      };

      const handleTouchStart = (e) => {
        const touch = e.touches[0];
        const rect = app.view.getBoundingClientRect();
        const x = touch.clientX - rect.left;
        const y = touch.clientY - rect.top;

        if (isInsideFloorPlan(x, y)) {
          e.stopPropagation();
          e.preventDefault();
        }
      };

      const handleTouchMove = (e) => {
        const touch = e.touches[0];
        const rect = app.view.getBoundingClientRect();
        const x = touch.clientX - rect.left;
        const y = touch.clientY - rect.top;

        if (isInsideFloorPlan(x, y)) {
          e.stopPropagation();
          e.preventDefault();
        }
      };

      app.view.addEventListener('touchstart', handleTouchStart, { passive: false });
      app.view.addEventListener('touchmove', handleTouchMove, { passive: false });
      app.view.addEventListener('touchend', (e) => {
        e.stopPropagation();
        e.preventDefault();
      }, { passive: false });
      app.view.addEventListener('touchcancel', (e) => {
        e.stopPropagation();
        e.preventDefault();
      }, { passive: false });
    }


    function createBox(pointAX, pointAY, width, height, boxswap, errormessage, name, Furn, tag) {
      if (Furn === "TRUE") {
        if (name.startsWith("VSMN_FURN_Door")) {
          // console.log(name);
          const box = new PIXI.Graphics();
          // box.beginFill(0x51829b);
          box.drawRect(0, 0, width, height);
          box.endFill();
          // Set the position based on pointA
          box.position.set(pointAX, pointAY);
          box.interactive = true;
          box.buttonMode = true;
          box.on('pointerdown', onDragStartBox);
          box.on('pointerup', onDragEndBox);
          box.on('pointermove', onDragMove);
          box.on('pointerupoutside', onDragEndBox);
          box.boxswap = boxswap;
          box.errormessage = errormessage;
          // box.sap = sap;
          box.name = name;
          // box.tag = tag;
          box.alpha = 1;
          return box;
        }
        else {
          const box = new PIXI.Graphics();
          box.lineStyle(2, 0x51829b);
          // box.beginFill(0xf8f9f8);
          box.drawRect(0, 0, width, height);
          box.endFill();
          // Set the position based on pointA
          box.position.set(pointAX, pointAY);
          box.interactive = true;
          box.buttonMode = true;
          box.on('pointerdown', onDragStartBox);
          box.on('pointerup', onDragEndBox);
          box.on('pointermove', onDragMove);
          box.on('pointerupoutside', onDragEndBox);
          box.boxswap = boxswap;
          box.errormessage = errormessage;
          box.name = name;
          box.alpha = 0.5;
          return box;
        }
      }
      else {
        const box = new PIXI.Graphics();

        if (name.startsWith("VSMN_Bedroom")) {
          box.beginFill(0xffffff);
        }
        else if (name.startsWith("VSMN_Toilet")) {
          box.beginFill(0xffffff);
        }
        else {
          box.beginFill(0xffffff);
        }

        box.drawRect(0, 0, width - 4, height - 4);
        box.endFill();
        // Set the position based on pointA
        box.position.set(pointAX + 2, pointAY + 2);
        box.interactive = true;
        box.buttonMode = true;
        box.on('pointerdown', onDragStartBox);
        box.on('pointerup', onDragEndBox);
        box.on('pointermove', onDragMove);
        box.on('pointerupoutside', onDragEndBox);
        box.boxswap = boxswap;
        box.errormessage = errormessage;
        // console.log(errormessage);
        box.name = name;
        box.tag = tag;
        box.alpha = .5;
        return box;
      }
    }

    function createShadowBox(pointAX, pointAY, width, height, boxswap, errormessage, name, Furn, tag) {
      const box = new PIXI.Graphics();
      box.beginFill(0xe5e500);
      box.drawRect(0, 0, width, height);
      box.endFill();
      // Set the position based on pointA
      box.position.set(pointAX, pointAY);
      box.interactive = true;
      box.buttonMode = true;
      box.on('pointerdown', onDragStartBox);
      box.on('pointerup', onDragEndBox);
      box.on('pointermove', onDragMove);
      box.on('pointerupoutside', onDragEndBox);
      box.boxswap = boxswap;
      box.errormessage = errormessage;
      // box.sap = sap;
      box.name = name;
      box.tag = tag
      console.log(tag);
      box.alpha = .3;
      return box;
    }

    document.addEventListener('pointermove', onDragMoveBox);
    let isDraggingBox = false;
    let dragBox = null;
    let shadowBox = null;
    // let canSwap = false; // Flag to coswappingntrol 
    // let hasSwapped = false; 

    function onDragStartBox(event) {
      isDraggingBox = true;
      dragBox = this;
      // console.log(dragBox.x);
      // console.log(dragBox.y);
      // console.log(dragBox.height);
      // console.log(dragBox.width);

      shadowBox = createShadowBox(dragBox.x, dragBox.y, dragBox.width, dragBox.height, null, null, null, null, dragBox.tag);
      shadowBox.visible = false;
      app.stage.addChild(shadowBox);
      shadowBox.position.set(dragBox.x, dragBox.y);
      // console.log(shadowBox.height);
      // console.log(shadowBox.width);
      // console.log(dragBox.height);
      // console.log(dragBox.width);
      shadowBox.height = dragBox.height;
      shadowBox.width = dragBox.width;
      shadowBox.visible = true;

      // canSwap = false; // Prevent swapping until moved over another box
      // hasSwapped = false; 
    }
    // let shouldSwap = false; // Flag to trigger the swap when dragging ends

    function handleResponse(response) {
      const newData = response.data;
      json_data = newData.json_data;

      app.stage.removeChildren();
      generateLines();
    }
    function handleError(error) {
      console.error('Error fetching new JSON data:', error);
    }
    // Define a function to handle swapping


    function handleSwapping(pixi_data, swapping_type) {
      // const url = `http://127.0.0.1:8000/api/swap-data/?pixi_data=${pixi_data}&swapping_type=${swapping_type}`;

      const url = `https://www.veshmn.com/api/swap-data/`;

      // Make a GET request to the Django API
      // console.log(pixi_data)
      fetch(url)
        .then(response => response.json())
        .then(data => {
          // Filter the array to find the exact match
          const matchingObject = data.find(item => item.pixi_data === pixi_data && item.swapping_type === swapping_type);
          id = matchingObject.swapped_plan_data;
          // Check if a matching object was found
          if (matchingObject) {
            // Handle the response data
            axios.get(`https://www.veshmn.com/api/pixi-data/${matchingObject.swapped_plan_data}/`)
              .then(handleResponse, matchingObject.swapped_plan_data)
              .catch(handleError);
          } else {
            console.error('No matching data found');
          }
        })
        .catch(error => {
          console.error('Error:', error);
        });
    }

    function onDragEndBox() {
      isDraggingBox = false;
      if (dragBox) {
        if (shadowBox) {
          shadowBox.visible = false;
        }
        for (const boxName in boxes) {
          if (boxName !== dragBox.name && !boxName.startsWith("VSMN_FURN_Door") && !boxName.startsWith("VSMN_FURN_Bed") && !boxName.startsWith("VSMN_FURN_Sink") && !boxName.startsWith("VSMN_FURN_Sofa")) {
            console.log(boxes[boxName]);
            const overlapArea = getOverlapArea(shadowBox, boxes[boxName]);
            // console.log(overlapArea);
            const dragBoxArea = dragBox.width * dragBox.height;
            if (overlapArea >= dragBoxArea * 0.2) {
              const tag = dragBox.tag;
              let token = false;
              const pixi_data = parseInt(id);
              let swapping_type;
              console.log(boxes[boxName].boxswap);
              console.log(tag);
              for (const temp in boxes[boxName].boxswap) {
                // console.log();
                console.log(tag);
                if (boxes[boxName].boxswap[temp] === tag) {
                  const DRAG_BOX_NAME = dragBox.tag;
                  console.log(DRAG_BOX_NAME);
                  const OVERLAP_AREA_NAME = boxes[boxName].tag;
                  console.log(OVERLAP_AREA_NAME);
                  token = true;
                  if ((DRAG_BOX_NAME.startsWith('P1') && OVERLAP_AREA_NAME.startsWith('P2')) || (OVERLAP_AREA_NAME.startsWith('P1') && DRAG_BOX_NAME.startsWith('P2'))) {
                    swapping_type = 'S12';
                  }
                  if ((DRAG_BOX_NAME.startsWith('P1') && OVERLAP_AREA_NAME.startsWith('P3')) || (OVERLAP_AREA_NAME.startsWith('P1') && DRAG_BOX_NAME.startsWith('P3'))) {
                    swapping_type = 'S13';
                  }
                  if ((DRAG_BOX_NAME.startsWith('P1') && OVERLAP_AREA_NAME.startsWith('P4')) || (OVERLAP_AREA_NAME.startsWith('P1') && DRAG_BOX_NAME.startsWith('P4'))) {
                    swapping_type = 'S14';
                  }
                  if ((DRAG_BOX_NAME.startsWith('P1') && OVERLAP_AREA_NAME.startsWith('P5')) || (OVERLAP_AREA_NAME.startsWith('P1') && DRAG_BOX_NAME.startsWith('P5'))) {
                    swapping_type = 'S15';
                  }
                  if ((DRAG_BOX_NAME.startsWith('P1') && OVERLAP_AREA_NAME.startsWith('P6')) || (OVERLAP_AREA_NAME.startsWith('P1') && DRAG_BOX_NAME.startsWith('P6'))) {
                    swapping_type = 'S16';
                  }
                  if ((DRAG_BOX_NAME.startsWith('P1') && OVERLAP_AREA_NAME.startsWith('P7')) || (OVERLAP_AREA_NAME.startsWith('P1') && DRAG_BOX_NAME.startsWith('P7'))) {
                    swapping_type = 'S17';
                  }
                  if ((DRAG_BOX_NAME.startsWith('P1') && OVERLAP_AREA_NAME.startsWith('P8')) || (OVERLAP_AREA_NAME.startsWith('P1') && DRAG_BOX_NAME.startsWith('P8'))) {
                    swapping_type = 'S18';
                  }
                  if ((DRAG_BOX_NAME.startsWith('P1') && OVERLAP_AREA_NAME.startsWith('P9')) || (OVERLAP_AREA_NAME.startsWith('P1') && DRAG_BOX_NAME.startsWith('P9'))) {
                    swapping_type = 'S19';
                  }
                  if ((DRAG_BOX_NAME.startsWith('P2') && OVERLAP_AREA_NAME.startsWith('P3')) || (OVERLAP_AREA_NAME.startsWith('P2') && DRAG_BOX_NAME.startsWith('P3'))) {
                    swapping_type = 'S23';
                  }
                  if ((DRAG_BOX_NAME.startsWith('P2') && OVERLAP_AREA_NAME.startsWith('P4')) || (OVERLAP_AREA_NAME.startsWith('P2') && DRAG_BOX_NAME.startsWith('P4'))) {
                    swapping_type = 'S24';
                  }
                  if ((DRAG_BOX_NAME.startsWith('P2') && OVERLAP_AREA_NAME.startsWith('P5')) || (OVERLAP_AREA_NAME.startsWith('P2') && DRAG_BOX_NAME.startsWith('P5'))) {
                    swapping_type = 'S25';
                  }
                  if ((DRAG_BOX_NAME.startsWith('P2') && OVERLAP_AREA_NAME.startsWith('P6')) || (OVERLAP_AREA_NAME.startsWith('P2') && DRAG_BOX_NAME.startsWith('P6'))) {
                    swapping_type = 'S26';
                  }
                  if ((DRAG_BOX_NAME.startsWith('P2') && OVERLAP_AREA_NAME.startsWith('P7')) || (OVERLAP_AREA_NAME.startsWith('P2') && DRAG_BOX_NAME.startsWith('P7'))) {
                    swapping_type = 'S27';
                  }
                  if ((DRAG_BOX_NAME.startsWith('P2') && OVERLAP_AREA_NAME.startsWith('P8')) || (OVERLAP_AREA_NAME.startsWith('P2') && DRAG_BOX_NAME.startsWith('P8'))) {
                    swapping_type = 'S28';
                  }
                  if ((DRAG_BOX_NAME.startsWith('P2') && OVERLAP_AREA_NAME.startsWith('P9')) || (OVERLAP_AREA_NAME.startsWith('P2') && DRAG_BOX_NAME.startsWith('P9'))) {
                    swapping_type = 'S29';
                  }
                  if ((DRAG_BOX_NAME.startsWith('P3') && OVERLAP_AREA_NAME.startsWith('P4')) || (OVERLAP_AREA_NAME.startsWith('P3') && DRAG_BOX_NAME.startsWith('P4'))) {
                    swapping_type = 'S34';
                  }
                  if ((DRAG_BOX_NAME.startsWith('P3') && OVERLAP_AREA_NAME.startsWith('P5')) || (OVERLAP_AREA_NAME.startsWith('P3') && DRAG_BOX_NAME.startsWith('P5'))) {
                    swapping_type = 'S35';
                  }
                  if ((DRAG_BOX_NAME.startsWith('P3') && OVERLAP_AREA_NAME.startsWith('P6')) || (OVERLAP_AREA_NAME.startsWith('P3') && DRAG_BOX_NAME.startsWith('P6'))) {
                    swapping_type = 'S36';
                  }
                  if ((DRAG_BOX_NAME.startsWith('P3') && OVERLAP_AREA_NAME.startsWith('P7')) || (OVERLAP_AREA_NAME.startsWith('P3') && DRAG_BOX_NAME.startsWith('P7'))) {
                    swapping_type = 'S37';
                  }
                  if ((DRAG_BOX_NAME.startsWith('P3') && OVERLAP_AREA_NAME.startsWith('P8')) || (OVERLAP_AREA_NAME.startsWith('P3') && DRAG_BOX_NAME.startsWith('P8'))) {
                    swapping_type = 'S38';
                  }
                  if ((DRAG_BOX_NAME.startsWith('P3') && OVERLAP_AREA_NAME.startsWith('P9')) || (OVERLAP_AREA_NAME.startsWith('P3') && DRAG_BOX_NAME.startsWith('P9'))) {
                    swapping_type = 'S39';
                  }
                  if ((DRAG_BOX_NAME.startsWith('P4') && OVERLAP_AREA_NAME.startsWith('P5')) || (OVERLAP_AREA_NAME.startsWith('P4') && DRAG_BOX_NAME.startsWith('P5'))) {
                    swapping_type = 'S45';
                  }
                  if ((DRAG_BOX_NAME.startsWith('P4') && OVERLAP_AREA_NAME.startsWith('P6')) || (OVERLAP_AREA_NAME.startsWith('P4') && DRAG_BOX_NAME.startsWith('P6'))) {
                    swapping_type = 'S46';
                  }
                  if ((DRAG_BOX_NAME.startsWith('P4') && OVERLAP_AREA_NAME.startsWith('P7')) || (OVERLAP_AREA_NAME.startsWith('P4') && DRAG_BOX_NAME.startsWith('P7'))) {
                    swapping_type = 'S47';
                  }
                  if ((DRAG_BOX_NAME.startsWith('P4') && OVERLAP_AREA_NAME.startsWith('P8')) || (OVERLAP_AREA_NAME.startsWith('P4') && DRAG_BOX_NAME.startsWith('P8'))) {
                    swapping_type = 'S48';
                  }
                  if ((DRAG_BOX_NAME.startsWith('P4') && OVERLAP_AREA_NAME.startsWith('P9')) || (OVERLAP_AREA_NAME.startsWith('P4') && DRAG_BOX_NAME.startsWith('P9'))) {
                    swapping_type = 'S49';
                  }
                  if ((DRAG_BOX_NAME.startsWith('P5') && OVERLAP_AREA_NAME.startsWith('P6')) || (OVERLAP_AREA_NAME.startsWith('P5') && DRAG_BOX_NAME.startsWith('P6'))) {
                    swapping_type = 'S56';
                  }
                  if ((DRAG_BOX_NAME.startsWith('P5') && OVERLAP_AREA_NAME.startsWith('P7')) || (OVERLAP_AREA_NAME.startsWith('P5') && DRAG_BOX_NAME.startsWith('P7'))) {
                    swapping_type = 'S57';
                  }
                  if ((DRAG_BOX_NAME.startsWith('P5') && OVERLAP_AREA_NAME.startsWith('P8')) || (OVERLAP_AREA_NAME.startsWith('P5') && DRAG_BOX_NAME.startsWith('P8'))) {
                    swapping_type = 'S58';
                  }
                  if ((DRAG_BOX_NAME.startsWith('P5') && OVERLAP_AREA_NAME.startsWith('P9')) || (OVERLAP_AREA_NAME.startsWith('P5') && DRAG_BOX_NAME.startsWith('P9'))) {
                    swapping_type = 'S59';
                  }
                  if ((DRAG_BOX_NAME.startsWith('P6') && OVERLAP_AREA_NAME.startsWith('P7')) || (OVERLAP_AREA_NAME.startsWith('P6') && DRAG_BOX_NAME.startsWith('P7'))) {
                    swapping_type = 'S67';
                  }
                  if ((DRAG_BOX_NAME.startsWith('P6') && OVERLAP_AREA_NAME.startsWith('P8')) || (OVERLAP_AREA_NAME.startsWith('P6') && DRAG_BOX_NAME.startsWith('P8'))) {
                    swapping_type = 'S68';
                  }
                  if ((DRAG_BOX_NAME.startsWith('P6') && OVERLAP_AREA_NAME.startsWith('P9')) || (OVERLAP_AREA_NAME.startsWith('P6') && DRAG_BOX_NAME.startsWith('P9'))) {
                    swapping_type = 'S69';
                  }
                  if ((DRAG_BOX_NAME.startsWith('P7') && OVERLAP_AREA_NAME.startsWith('P8')) || (OVERLAP_AREA_NAME.startsWith('P7') && DRAG_BOX_NAME.startsWith('P8'))) {
                    swapping_type = 'S78';
                  }
                  if ((DRAG_BOX_NAME.startsWith('P7') && OVERLAP_AREA_NAME.startsWith('P9')) || (OVERLAP_AREA_NAME.startsWith('P7') && DRAG_BOX_NAME.startsWith('P9'))) {
                    swapping_type = 'S79';
                  }
                  if ((DRAG_BOX_NAME.startsWith('P8') && OVERLAP_AREA_NAME.startsWith('P9')) || (OVERLAP_AREA_NAME.startsWith('P8') && DRAG_BOX_NAME.startsWith('P9'))) {
                    swapping_type = 'S89';
                  }                  
                  handleSwapping(pixi_data, swapping_type);
                }
              }
              if (!token) {
                if (boxes[dragBox.name] && boxes[dragBox.name].errormessage) {
                  console.log(boxes[dragBox.name].errormessage);
                  console.log(dragBox.name);
                  const errorMessage = boxes[dragBox.name].errormessage.find(message => {
                    const messageParts = message.split(':');
                    return messageParts.length > 1 && messageParts[0].trim() === boxes[boxName].tag;
                  });
                  console.log(errorMessage);
                  if (errorMessage) {
                    const errorMessageAfterColon = errorMessage.split(':')[1].trim();
                    showAlert(errorMessageAfterColon);
                  }
                } else {
                  console.error(`No error messages found for box: ${dragBox.name}`);
                  dragBox = null;
                }
                break;
              }
            }
          }
        }
        dragBox = null;
      } else {
        // const messagetemp="Swap is not possible for this area.";
        // showAlert(messagetemp);
      }
    }

    function onDragMoveBox(event) {
      if (isDraggingBox && dragBox !== null) {
        const globalPos = event.targetTouches
          ? { x: event.targetTouches[0].clientX, y: event.targetTouches[0].clientY }
          : { x: event.clientX, y: event.clientY };
    
        shadowBox.position.x =
          (globalPos.x - app.renderer.view.getBoundingClientRect().left) / scaleFactor -
          shadowBox.width / 2;
        shadowBox.position.y =
          (globalPos.y - app.renderer.view.getBoundingClientRect().top) / scaleFactor -
          shadowBox.height / 2;
    
        let isOverlapCorrect = false;
    
        for (const boxName in boxes) {
          const targetBox = boxes[boxName];
          
          // Ensure targetBox exists and has the boxswap property
          if (targetBox && targetBox.boxswap && 
              boxName !== dragBox.name && 
              !boxName.startsWith("VSMN_FURN_Door") && 
              !boxName.startsWith("VSMN_FURN_Bed") && 
              !boxName.startsWith("VSMN_FURN_Sink") && 
              !boxName.startsWith("VSMN_FURN_Sofa")) {
              
            if (checkOverlap(shadowBox, targetBox, scaleFactor) && dragBox !== targetBox) {
              const overlapArea = getOverlapArea(shadowBox, targetBox);
              const targetBoxArea = targetBox.width * targetBox.height;
    
              if (overlapArea >= targetBoxArea * 0.2) {
                const dragBoxTag = dragBox.tag;
                
                // Check if the targetBox can swap with the dragBox based on tags
                if (targetBox.boxswap.includes(dragBoxTag)) {
                  shadowBox.tint = 0x00FF00; // Green color for valid swap area
                  isOverlapCorrect = true;
                  break;
                }
              }
            }
          }
        }
    
        if (!isOverlapCorrect) {
          // No valid overlap or swap found, change shadowBox color to red
          shadowBox.tint = 0xFF0000; // Red color
        }
      }
    }
            
    function checkOverlap(boxA, boxB) {
      // let scaleFactor=.5;
      if (boxA && boxB && boxA.getBounds && boxB.getBounds) {
        const boundsA = boxA.getBounds();
        const boundsB = boxB.getBounds();

        boundsA.x /= scaleFactor;
        boundsA.y /= scaleFactor;
        boundsA.width /= scaleFactor;
        boundsA.height /= scaleFactor;

        boundsB.x /= scaleFactor;
        boundsB.y /= scaleFactor;
        boundsB.width /= scaleFactor;
        boundsB.height /= scaleFactor;

        return (
          boundsA.x < boundsB.x + boundsB.width &&
          boundsA.x + boundsA.width > boundsB.x &&
          boundsA.y < boundsB.y + boundsB.height &&
          boundsA.y + boundsA.height > boundsB.y
        );
      } else {
        console.error('boxA or boxB is undefined or getBounds method is missing');
        return false;
      }
    }

    function getOverlapArea(boxA, boxB) {
      // let scaleFactor=.5;
      if (!boxA || !boxA.getBounds || !boxB || !boxB.getBounds) {
        console.error("Invalid boxes or missing getBounds method.");
        return 0;
      }
      const boundsA = boxA.getBounds();
      const boundsB = boxB.getBounds();
      boundsA.x /= scaleFactor;
      boundsA.y /= scaleFactor;
      boundsA.width /= scaleFactor;
      boundsA.height /= scaleFactor;
      boundsB.x /= scaleFactor;
      boundsB.y /= scaleFactor;
      boundsB.width /= scaleFactor;
      boundsB.height /= scaleFactor;
      const intersectX = Math.max(0, Math.min(boundsA.x + boundsA.width, boundsB.x + boundsB.width) - Math.max(boundsA.x, boundsB.x));
      const intersectY = Math.max(0, Math.min(boundsA.y + boundsA.height, boundsB.y + boundsB.height) - Math.max(boundsA.y, boundsB.y));
      return intersectX * intersectY;
    }

    function establishLineConnections() {
      const proximityThreshold = 1; // Adjust this threshold as needed
      for (const lineA of lines) {
        const lineAData = lineA.data.originalData;
        const connectedEndpoints = [];
        for (const lineB of lines) {
          if (lineA !== lineB) {
            const lineBData = lineB.data.originalData;
            if (
              isPointOnLine(lineBData.x1, lineBData.y1, lineAData.x1, lineAData.y1, lineAData.x2, lineAData.y2, proximityThreshold)
            ) {
              connectedEndpoints.push({ line: lineB, endpointX: 'x1', endpointY: 'y1' });
            } else if (
              isPointOnLine(lineBData.x2, lineBData.y2, lineAData.x1, lineAData.y1, lineAData.x2, lineAData.y2, proximityThreshold)
            ) {
              connectedEndpoints.push({ line: lineB, endpointX: 'x2', endpointY: 'y2' });
            }
          }
        }
        lineA.connectedEndpoints = connectedEndpoints;
      }
    }
    function isPointOnLine(x, y, x1, y1, x2, y2, threshold) {
      const dist = Math.hypot(x - x1, y - y1) + Math.hypot(x - x2, y - y2);
      const lineLength = Math.hypot(x1 - x2, y1 - y2);
      return dist >= lineLength - threshold && dist <= lineLength + threshold;
    }
    let isDragging = false;
    let dragOffsetX, dragOffsetY, draggedLine;

    function onDragStart(event) {
      const line = event.currentTarget;
      let checkDrag = line.data.originalData.MOVEMENT;
      // console.log(checkDrag=== "TRUE");
      if (checkDrag === "TRUE") {
        isDragging = true;
        draggedLine = line;
        const { x, y } = event.data.getLocalPosition(draggedLine.parent);
        dragOffsetX = x;
        dragOffsetY = y;
        draggedLine.connectedEndpoints = draggedLine.connectedEndpoints || [];
      } else {
        // For lines other than dragline, prevent dragging
        return;
      }
    }
    function onDragEnd() {
      isDragging = false;
      draggedLine = null;
    }

    let alertDisplayed = false; // Flag to track whether the alert is already displayed

    function showAlert(errorMessage) {
      const modalContainer = new PIXI.Container();
      const modalBackground = new PIXI.Graphics();
      modalBackground.beginFill(0x000000, 0.75);
      modalBackground.drawRect(0, 0, app.screen.width, app.screen.height);
      modalBackground.endFill();
      modalBackground.interactive = true; // Make it clickable

      // Close modal when clicking on the background
      modalBackground.on('pointerdown', () => {
        modalContainer.destroy(); // Remove the modal container
      });

      const image = PIXI.Sprite.from(alert_image);
      image.anchor.set(0.5);
      image.x = app.screen.width * 3 / 4;
      image.y = app.screen.height * 3 / 4;

      const prefix = "Arki Says: ";
      const textStyle = new PIXI.TextStyle({
        fill: '000000',
        fontSize: 24,
        wordWrap: true,
        fontFamily: 'roboto',
        wordWrapWidth: 200,
        align: 'center',
      });

      const message = new PIXI.Text(prefix + errorMessage, textStyle);
      message.anchor.set(0.5);
      message.x = app.screen.width / 2;
      message.y = app.screen.height / 2 - 30;

      // Add a background segment with rounded corners
      const backgroundSegment = new PIXI.Graphics();
      backgroundSegment.beginFill(0xffffff);
      backgroundSegment.drawRoundedRect(message.x - message.width / 2 - 10, message.y - message.height / 2 - 10, message.width + 20, message.height + 20, 10); // Adjust the last parameter for corner radius
      backgroundSegment.endFill();
      modalContainer.addChild(modalBackground, image, backgroundSegment, message);
      app.stage.addChild(modalContainer);
    }

    function onDragMove(event) {
      if (isDragging) {
        // const a = draggedLine.data.originalData.x1;
        // const b = draggedLine.data.originalData.y1;
        // const c = draggedLine.data.originalData.x2;
        // const d = draggedLine.data.originalData.y2;
        const newPosition = event.data.getLocalPosition(draggedLine.parent);
        const deltaX = newPosition.x - dragOffsetX;
        const deltaY = newPosition.y - dragOffsetY;
        // const BoxAttached = draggedLine.data.originalData.boxattached;
        // Calculate new positions
        const newX = draggedLine.x + deltaX;
        const newY = draggedLine.y + deltaY;
        // Reset error message if within bounds
        const startpoint = -draggedLine.data.originalData.START;
        const endpoint = draggedLine.data.originalData.END;
        const Token = draggedLine.data.originalData.Token;
        // console.log(Token);
        if (draggedLine.data.originalData.DIRECTION === "X") {
          if (newY <= startpoint || newY >= endpoint) {
            if (!alertDisplayed) {
              const errorMessage = draggedLine.data.originalData['ERROR-MESSAGE'];
              showAlert(errorMessage);
              // window.alert(errorMessage);
              alertDisplayed = true; // Set the flag to true after alert display
            }
            return; // Stops further movement of the line
          } else {
            alertDisplayed = false; // Reset the flag when the condition is met
          }
          draggedLine.data.originalData.y1 += deltaY;
          draggedLine.data.originalData.y2 += deltaY;
          draggedLine.y += deltaY;
          // console.log(draggedLine.data.originalData);
        }
        else if (draggedLine.data.originalData.DIRECTION === "Y") {
          if (newX <= startpoint || newX >= endpoint) {
            if (!alertDisplayed) {
              const errorMessage = draggedLine.data.originalData['ERROR-MESSAGE'];
              showAlert(errorMessage);
              // window.alert(errorMessage);
              alertDisplayed = true; // Set the flag to true after alert display
            }
            return; // Stops further movement of the line
          } else {
            alertDisplayed = false; // Reset the flag when the condition is met
          }
          draggedLine.data.originalData.x1 += deltaX;
          draggedLine.data.originalData.x2 += deltaX;
          draggedLine.x += deltaX
          // console.log(draggedLine.data.originalData);
        }
        // Update connected lines
        for (const endpoint of draggedLine.connectedEndpoints) {
          if (draggedLine.data.originalData.DIRECTION === "X") {
            endpoint.line.data.originalData[endpoint.endpointY] += deltaY;
          }
          else {
            endpoint.line.data.originalData[endpoint.endpointX] += deltaX;
          }
          const graphics = endpoint.line.getChildAt(0);
          graphics.clear().lineStyle(4, 0x040505)
            .moveTo(endpoint.line.data.originalData.x1, endpoint.line.data.originalData.y1)
            .lineTo(endpoint.line.data.originalData.x2, endpoint.line.data.originalData.y2);
        }
        const LineName = draggedLine.data.Name;
        for (const key in MovableLine) {
          const LineToChange = MovableLine[key];
          if (LineName !== LineToChange.Name) {
            if (LineToChange.Token === Token) {
              if (draggedLine.data.originalData.DIRECTION === "X") {
                LineToChange.position.y += deltaY;
              }
              else {
                LineToChange.position.x += deltaX;
              }
            }
          }
        }
        for (const key in MovableArc) {
          const ArcToChange = MovableArc[key];
          if (ArcToChange.Token === Token) {
            if (draggedLine.data.originalData.DIRECTION === "X") {
              ArcToChange.position.y += deltaY;
            }
            else {
              ArcToChange.position.x += deltaX;
            }
          }
        }
        for (const key in MovableRect) {
          const RectToChange = MovableRect[key];
          if (RectToChange.Token === Token) {
            if (draggedLine.data.originalData.DIRECTION === "X") {
              RectToChange.position.y += deltaY;
            }
            else {
              RectToChange.position.x += deltaX;
            }
          }
        }
        // for (const boxKey in BoxAttached) {
        //   // console.log(BoxAttached[boxKey]);
        //   const BoxToChange = boxes[BoxAttached[boxKey]];
        //   // console.log(BoxToChange);
        //   // const originalWidth = BoxToChange.width;
        //   if (draggedLine.data.originalData.DIRECTION === "X") {
        //     // draggedLine.data.originalData.y1 += deltaY;
        //     // draggedLine.data.originalData.y2 += deltaY;
        //     const e = BoxToChange.position.x;
        //     const f = BoxToChange.position.y;
        //     // console.log(BoxToChange.x);
        //     // console.log(BoxToChange.y);
        //     if (isPointOnLine(e, f, a, b, c, d, 2)) {
        //       const originalHeight = BoxToChange.height;
        //       const newHeight = originalHeight - deltaY;
        //       BoxToChange.height = newHeight;
        //       BoxToChange.position.y += deltaY;
        //       // console.log(e,f);
        //       // updateImageSizes(BoxToChange,BoxToChange.scale.x, BoxToChange.scale.y);
        //     }
        //     else {
        //       const originalHeight = BoxToChange.height;
        //       const newHeight = originalHeight + deltaY;
        //       BoxToChange.height = newHeight;
        //       // updateImageSizes(BoxToChange,BoxToChange.scale.x, BoxToChange.scale.y);
        //     }
        //   }
        //   else if (draggedLine.data.originalData.DIRECTION === "Y") {
        //     // draggedLine.data.originalData.x1 += deltaX;
        //     // draggedLine.data.originalData.x2 += deltaX;
        //     let g = BoxToChange.position.x;
        //     let h = BoxToChange.position.y;
        //     if (isPointOnLine(g, h, a, b, c, d, 2)) {
        //       const originalWidth = BoxToChange.width;
        //       const newWidth = originalWidth - deltaX;
        //       BoxToChange.width = newWidth;
        //       BoxToChange.position.x += deltaX;
        //       // updateImageSizes(BoxToChange,BoxToChange.scale.x, BoxToChange.scale.y);
        //     }
        //     else {
        //       const originalWidth = BoxToChange.width;
        //       const newWidth = originalWidth + deltaX;
        //       BoxToChange.width = newWidth;
        //       // updateImageSizes(BoxToChange,BoxToChange.scale.x, BoxToChange.scale.y);
        //     }
        //   }
        // }
        // Update the drag offset for the next movement
        dragOffsetX = newPosition.x;
        dragOffsetY = newPosition.y;
      }
    }
    function getCookie(name) {
      const value = `; ${document.cookie}`;
      const parts = value.split(`; ${name}=`);
      if (parts.length === 2) return parts.pop().split(';').shift();
    }

    function PostFinalData() {
      let postData = {
        json_data: {} // Initialize the nested json_data object
      };
      for (const lineA of lines) {
        if (lineA.data.originalData.MOVEMENT === "TRUE") {
          const key = lineA.data.Name;
          const dataToPost = {
            "x1": lineA.data.originalData.x1,
            "y1": lineA.data.originalData.y1,
            "x2": lineA.data.originalData.x2,
            "y2": lineA.data.originalData.y2
          };
          // Directly assign dataToPost to the key within json_data          
          postData.json_data[key] = dataToPost;
          postData.json_data["name"] = data.name;
          postData.json_data["mobile"] = data.mobile;
        }
      }
      const csrftoken = getCookie('csrftoken'); // Define getCookie function
      // Set headers with CSRF token
      console.log(csrftoken);
      const config = {
        headers: {
          'X-CSRFToken': csrftoken // Include CSRF token in headers
        }
      };
      console.log(postData);
      axios.post("https://www.veshmn.com/api/output-data/create_output_data/", postData, config)
        .then(response => {
          console.log('Data posted successfully!', response.data);
        })
        .catch(error => {
          console.error('Error posting data:', error);
        });
    }
    const captureImage = () => {
      const renderer = PIXI.autoDetectRenderer({
        width: 800,
        height: 1000, // Set your canvas size here
        backgroundColor: 0xffffff, // Background color
      });
      try {
        // Render Pixi stage to the renderer
        renderer.render(app.stage);
        // Convert the renderer view to a Blob object and append to FormData
        renderer.view.toBlob((blob) => {
          const formData = new FormData();
          formData.append('image', blob, 'image.png'); // 'image' should match the field name in Django
          // Append name and mobile data
          formData.append('name', data.name);
          formData.append('mobile', data.mobile);

          // Send the Blob image data to the backend
          sendImageToBackend(formData);

          // PostFinalData called here to ensure it runs after rendering
          PostFinalData();
        }, 'image/png');
      }
      catch (error) {
        console.error('PIXI rendering error:', error);
      }
    };
    // Function to send image data to the Django backend
    const sendImageToBackend = (formData) => {
      // Make a POST request to your Django backend to send the image data
      const csrftoken = getCookie('csrftoken'); // Define getCookie function
      // Set headers with CSRF token
      // console.log(csrftoken);
      const config = {
        headers: {
          'Content-Type': 'multipart/form-data',
          'X-CSRFToken': csrftoken // Include CSRF token in headers
        }
      };
      axios.post("https://www.veshmn.com/api/image-output/", formData, config, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
        .then(response => {
          // Handle successful response
          alert('Changes in plans has sent successfully!');
          console.log('Image sent to Django:', response.data);
        })
        .catch(error => {
          // Handle errors
          console.error('Error sending image to Django:', error);
        });
    };
    // Trigger image capture on button click
    const captureButton = document.getElementById('capture-button');
    if (captureButton) {
      captureButton.addEventListener('click', captureImage);
    }
    // Cleanup Pixi when component unmounts
    return () => {
      app.destroy(true); // true to remove the canvas element
    };
  }, [Width, Height, planId, data, location.state]);

  return (
    <div>
      <div id="pixi-container" />
      <button id="capture-button" type="button" class="btn btn-primary">
        Save Changes
      </button>
      {loading && (
        <div className="loader" >
          <img src={loaderImage} alt="Loading..." style={{ width: '100%', height: '100%', animation: 'spin 5s linear infinite' }} />
        </div>
      )}
    </div>
  );
};

export default PixiComponent;